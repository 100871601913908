import Button from '@/components/button';
import { Form } from '@/components/form';
import { InputField, SelectField, TextArea } from '@/components/input';
import {
    AddPersonalInfo,
    useGetMyPersonalInfo,
} from '@/core/hooks/personal_information';
import { PersonalInfoPayload, PersonalType } from '@/core/types/personal_info';
import { useEffect, useState } from 'react';
import { z } from 'zod';
import { PhoneInput } from 'react-international-phone';
import 'react-international-phone/style.css';
import { countries } from 'countries-list';
import {
    AcademicCapIcon,
    HomeIcon,
    SparklesIcon,
    SpeakerWaveIcon,
} from '@heroicons/react/24/outline';
import IconPlayCircle from '@/components/Icon/IconPlayCircle';
import IconLayoutGrid from '@/components/Icon/IconLayoutGrid';
import CreateAddress from '../address';
import AcademicInfo from '../academics';
import ExperiencesInfo from '../experience';
import LanguageInfo from '../languages';
import ReferenceInfo from '../references';
import IconMenuScrumboard from '@/components/Icon/Menu/IconMenuScrumboard';
import IconUser from '@/components/Icon/IconUser';
import { CircularProgress } from '@mui/material';
import { GetCategories } from '@/core/hooks/category';
import { useNavigate } from 'react-router-dom';
import { useDispatch } from 'react-redux';
import { setPageTitle } from '@/store/themeConfigSlice';
import IconCloudDownload from '@/components/Icon/IconCloudDownload';
import FileUpload from '../upload';

export type PersonalInputType = {
    email: string;
    firstName: string;
    middleName: string;
    lastName: string;
    date_of_birth: string;
    gender: string;
    facebook:string;
    disability_info:string;
    maritalStatus: string;
    phoneNumber: string;
    linkedIn: string;
    
    passport_expired_data: string;

    Nationality: string;
    citizenship: string;
    hasPassport: any;
    passportNumber: string;
 
    disability: any;
};

function CreatePersonalInfo({ activeTab2, setActiveTab2 }: any) {
    const {
        handleGetMyPersonalInfo,
        loadingInformation,
        personalInfo: data,
    } = useGetMyPersonalInfo();

    const { handleAddPersonalInfo, LoadingAddPersoanlInfo } = AddPersonalInfo();
    const [validationErrors, setValidationErrors] = useState<
        PersonalType | any
    >();

    const [formData, setFormData] = useState<PersonalInputType>({
        firstName: data?.firstName || '',
        middleName: data?.middleName || '',
        email: data?.email || '',
        phoneNumber: data?.phoneNumber || '',
        lastName: data?.lastName || '',
        date_of_birth: data?.date_of_birth || '',
        gender: data?.gender || '',
        maritalStatus: data?.maritalStatus || '',
        facebook:data?.facebook || '',
        linkedIn: data?.linkedIn || '',
        disability_info:data?.disability_info|| '',
        Nationality: data?.Nationality || '',
        citizenship: data?.citizenship || '',
        hasPassport: data?.hasPassport || '',
        passportNumber: data?.passportNumber || null,
       
       
        disability: data?.disability || '',
        passport_expired_data: data?.passport_expired_data || null,
    });

    const [selectedCountry, setSelectedCountry] = useState<string>(
        formData?.Nationality || ''
    );

   
    const [PhoneCountry, setPhoneCountry] = useState<string>('250');


    function findCountryByName(countriesObject:any, countryName:any) {
        for (const countryCode in countriesObject) {
          if (countriesObject.hasOwnProperty(countryCode)) {
            const country = countriesObject[countryCode];
            if (country.name === countryName) {
              return country;
            }
          }
        }
        return null; 
      }
      console.log(PhoneCountry)
    useEffect(() => {
      const country = findCountryByName(countries, selectedCountry);


      setPhoneCountry(country?.phone[0])
      
    }, [selectedCountry,countries])
    
    const [selectedDate, setSelectedDate] = useState(
        data?.date_of_birth ? new Date(data.date_of_birth) : new Date()
    );
    const [expiryDate, setExpiryDate] = useState<string | null>(
        data?.passport_expired_data || null
    );
    const [hasDisability,sethasDisability]= useState(false)
    const [hasPassport, setHasPassport] = useState(data?.hasPassport || false);
    const [phone, setPhone] = useState(data?.phoneNumber || '');
    const [loading, setLoading] = useState(false);

    useEffect(() => {
        handleGetMyPersonalInfo();
    }, []);

    useEffect(() => {
        setFormData({
            firstName: data?.firstName || '',
            middleName: data?.middleName || '',
            email: data?.email || '',
            phoneNumber: data?.phoneNumber || '',
            lastName: data?.lastName || '',
            date_of_birth: data?.date_of_birth || '',
            gender: data?.gender || '',
            maritalStatus: data?.maritalStatus || '',
            linkedIn: data?.linkedIn || '',
            facebook: data?.facebook || '',
            Nationality: data?.Nationality || '',
            citizenship: data?.citizenship || '',
            hasPassport: data?.hasPassport || false,
            passportNumber: data?.passportNumber || null,
            disability_info:data?.disablity_info || null,
            disability: data?.disability || false,
            passport_expired_data: data?.passport_expired_data || null,
            
        });
        setPhone(data?.phoneNumber || '');
        setExpiryDate(data?.passport_expired_data || null);
        
    }, [data]);

    console.log(countries)
    const countryOptions = Object.values(countries).map(country => ({
        value: country.name,
        text: country.name,
    }));

    const { loadingCategories, handleGetCategories, categories } =
        GetCategories();

    useEffect(() => {
        handleGetMyPersonalInfo();
        handleGetCategories();
    }, []);

    const handleSubmit = async () => {
        const errors: any = [];
    
        if (!formData.gender) {
            errors.push({ field: 'gender', message: 'Gender is required' });
        }
        if (!formData.date_of_birth) {
            errors.push({
                field: 'date_of_birth',
                message: 'Date of birth is required',
            });
        }
        
        if (!formData.maritalStatus) {
            errors.push({
                field: 'maritalStatus',
                message: 'Marital Status is required',
            });
        }
        if (!formData.Nationality) {
            errors.push({
                field: 'Nationality',
                message: 'Nationality is required',
            });
        }
        if (!formData.citizenship) {
            errors.push({
                field: 'citizenship',
                message: 'Citizenship is required',
            });
        }
    
        console.log(errors);
        setValidationErrors(errors);
    
        if (Object.keys(errors).length > 0) {
            setLoading(false);
            return;
        }
    
        // Convert boolean values to actual booleans
        formData.hasPassport = formData.hasPassport === 'true';

        formData.disability = formData.disability === 'true';
    
        await handleAddPersonalInfo(formData, data?.id);
    
        setLoading(false);
        setActiveTab2(2);
    };
    const dispatch = useDispatch();
    useEffect(() => {
        dispatch(setPageTitle('Personal Informations'));
    }, [dispatch]);

    return (
        <div className="">
            <div className="panel">
                <form>
                    <div className="w-full">
                        {loadingInformation ? (
                            <div>
                                <CircularProgress />
                            </div>
                        ) : (
                            <div className="px-4 flex flex-col gap-4 text-sm mb-3">
                                <div className="flex flex-col md:flex-row gap-2">
                                    <InputField
                                        type="text"
                                        label="First Name"
                                        defaultValue={formData.firstName}
                                        onChange={e =>
                                            setFormData({
                                                ...formData,
                                                firstName: e.target.value,
                                            })
                                        }
                                        error={validationErrors?.firstName}
                                    />
                                    <InputField
                                        type="text"
                                        label="Middle Name"
                                        defaultValue={formData.middleName}
                                        onChange={e =>
                                            setFormData({
                                                ...formData,
                                                middleName: e.target.value,
                                            })
                                        }
                                    />
                                    <InputField
                                        type="text"
                                        label="Last Name"
                                        defaultValue={formData.lastName}
                                        onChange={e =>
                                            setFormData({
                                                ...formData,
                                                lastName: e.target.value,
                                            })
                                        }
                                    />
                                </div>
                                <div className="flex flex-col md:flex-row gap-2">
                                    <InputField
                                        type="date"
                                        label="Date of birth"
                                        defaultValue={
                                            formData?.date_of_birth
                                                .toString()
                                                .split('T')[0]
                                        }
                                        onChange={e =>
                                            setFormData({
                                                ...formData,
                                                date_of_birth: e.target.value,
                                            })
                                        }
                                        error={validationErrors?.find(
                                            (error: { field: string }) =>
                                                error.field === 'date_of_birth'
                                        )}
                                    />
                                    <SelectField
                                        placeholder="Select Gender"
                                        label="Gender"
                                        defaultValue={formData.gender}
                                        onChange={e =>
                                            setFormData({
                                                ...formData,
                                                gender: e.target.value,
                                            })
                                        }
                                        options={[
                                            { value: 'male', text: 'Male' },
                                            { value: 'female', text: 'Female' },
                                            {
                                                value: 'prefer_not_to_say',
                                                text: 'Prefer not to say',
                                            },
                                        ]}
                                        error={validationErrors?.find(
                                            (error: { field: string }) =>
                                                error.field === 'gender'
                                        )}
                                    />

                                    <SelectField
                                        placeholder="Select Marital Status"
                                        label="Marital Status"
                                        defaultValue={formData.maritalStatus}
                                        onChange={e =>
                                            setFormData({
                                                ...formData,
                                                maritalStatus: e.target.value,
                                            })
                                        }
                                        options={[
                                            { value: 'single', text: 'Single' },
                                            {
                                                value: 'married',
                                                text: 'Married',
                                            },
                                            {
                                                value: 'divorced',
                                                text: 'Divorced',
                                            },
                                            {
                                                value: 'widowed',
                                                text: 'Widowed',
                                            },
                                            {
                                                value: 'prefer_not_to_say',
                                                text: 'Prefer not to say',
                                            },
                                        ]}
                                        error={validationErrors?.find(
                                            (error: { field: string }) =>
                                                error.field === 'maritalStatus'
                                        )}
                                    />
                                </div>
                               
                                <div className="flex flex-col md:flex-row gap-2">
                                    <SelectField
                                        placeholder="Select Country"
                                        isLoading={LoadingAddPersoanlInfo}
                                        label="Nationality"
                                        defaultValue={formData?.Nationality}
                                        onChange={e =>{
                                            setSelectedCountry(e.target.value),
                                            setFormData({
                                                ...formData,
                                                
                                                Nationality: e.target.value,
                                               
                                          })}
                                        }
                                        error={validationErrors?.find(
                                            (error: { field: string }) =>
                                                error.field === 'Nationality'
                                        )}
                                        options={countryOptions}
                                    />

                                    <SelectField
                                        placeholder="Select Citizenship"
                                        isLoading={LoadingAddPersoanlInfo}
                                        label="Citzenship"
                                        defaultValue={formData?.citizenship}
                                        error={validationErrors?.find(
                                            (error: { field: string }) =>
                                                error.field === 'citizenship'
                                        )}
                                        options={countryOptions}
                                        onChange={e =>
                                            setFormData({
                                                ...formData,
                                                citizenship: e.target.value,
                                            })
                                        }
                                    />
                                </div>
                                <div className="flex flex-col md:flex-row gap-2">
                                    <div className="w-fit mb-2 ">
                                        <label htmlFor="">Phone Number </label>
                                        <div>
                                            <InputField 
                                            defaultValue={formData?.phoneNumber }
                                                className="md:w-[240px] w-full  "
                                                onChange={phone =>
                                                    setPhone(phone)
                                                }
                                            />
                                        </div>
                                    </div>

                                    <InputField
                                        type="text"
                                        isLoading={LoadingAddPersoanlInfo}
                                        label="LinkedIn"
                                        defaultValue={formData.linkedIn}
                                        onChange={e =>
                                            setFormData({
                                                ...formData,
                                                linkedIn: e.target.value,
                                            })
                                        }
                                    />
                                  
                                  <InputField
                                        type="text"
                                        isLoading={LoadingAddPersoanlInfo}
                                        label="Github"
                                        defaultValue={formData.facebook}
                                        onChange={e =>
                                            setFormData({
                                                ...formData,
                                                facebook: e.target.value,
                                            })
                                        }
                                    />
                                </div>
                                
                                <div className="flex flex-col md:flex-row gap-2">
                                    <SelectField
                                        placeholder="Select Passport Status"
                                        isLoading={LoadingAddPersoanlInfo}
                                        label="Has Passport"
                                        defaultValue={false}
                                        options={[
                                            { value: true, text: 'Yes' },
                                            { value: false, text: 'No' },
                                        ]}
                                        onChange={(e: any) => {
                                            setFormData({
                                                ...formData,
                                                hasPassport: e.target.value,
                                            });
                                            setHasPassport(
                                                e.target.value === 'true'
                                            );
                                        }}
                                    />

                                    {hasPassport && (
                                        <>
                                            <InputField
                                                type="text"
                                                label="Passport Number "
                                                placeholder="Enter Passport Number"
                                                defaultValue={
                                                    formData?.passportNumber
                                                }
                                                onChange={e =>
                                                    setFormData({
                                                        ...formData,
                                                        passportNumber:
                                                            e.target.value,
                                                    })
                                                }
                                            />
                                            <InputField
                                                type="date"
                                                label="Passport Expiration Date "
                                                placeholder="Enter Expiration date"
                                                defaultValue={
                                                    formData?.passport_expired_data
                                                }
                                                onChange={e =>
                                                    setFormData({
                                                        ...formData,
                                                        passport_expired_data:
                                                            e.target.value,
                                                    })
                                                }
                                            />
                                        </>
                                    )}
                                </div>
                                <div className="flex flex-col md:flex-row gap-2">
                                    
                                    
                                    <SelectField
                                        placeholder="Select Disability Status"
                                        isLoading={LoadingAddPersoanlInfo}
                                        defaultValue={formData?.disability}
                                        label="Has Disability"
                                        options={[
                                            { value: true, text: 'Yes' },
                                            { value: false, text: 'No' },
                                        ]}
                                        onChange={(e: any) =>
                                        {    setFormData({
                                                ...formData,
                                                disability: e.target.value,
                                            }
                                          
                                        )
                                        sethasDisability(e.target.value==='true')
                                        
                                    }
                                        }
                                    />
                                    {
                                        hasDisability  && (
                                            <TextArea
                                        
                                            label="Disablity Information"
                                            placeholder="More information on disability"
                                            defaultValue={
                                                formData?.passport_expired_data
                                            }
                                            className='h-32'
                                            onChange={(e: any) =>
                                                setFormData({
                                                    ...formData,
                                                    disability_info:
                                                        e.target.value,
                                                })
                                            }
                                        />
                                        )
                                    }
                                     
                                </div>
                                <div className="my-3 flex justify-end">
                                    <button
                                        type="button"
                                        className="btn btn-primary ltr:ml-auto rtl:mr-auto"
                                        disabled={loading}
                                        onClick={handleSubmit}
                                    >
                                        Next
                                    </button>
                                </div>
                            </div>
                        )}
                    </div>
                </form>
            </div>
        </div>
    );
}

export default function ProfileInfo() {
    const [activeTab2, setActiveTab2] = useState<any>(1);
    const navigate = useNavigate();
    return (
        <div className="inline-block w-full">
            <ul className="mb-5 grid  grid-cols-3 md:grid-cols-6">
                <li>
                    <div
                        className={`${
                            activeTab2 === 1 ? '!bg-primary text-white' : ''
                        }
                    flex items-center justify-center rounded-full bg-[#f3f2ee] p-2.5 dark:bg-[#1b2e4b]`}
                        onClick={() => setActiveTab2(1)}
                    >
                        <HomeIcon className="w-5 h-5 " />
                    </div>
                    <span className="text-center block mt-2">Personal</span>
                </li>
                <li>
                    <div
                        className={`${
                            activeTab2 === 2 ? '!bg-primary text-white' : ''
                        }
                    flex items-center justify-center rounded-full bg-[#f3f2ee] p-2.5 dark:bg-[#1b2e4b]`}
                        onClick={() => setActiveTab2(2)}
                    >
                        <IconLayoutGrid className="h-5 w-5 " />
                    </div>
                    <span className="text-center block mt-2">Address</span>
                </li>
                <li>
                    <div
                        className={`${
                            activeTab2 === 3 ? '!bg-primary text-white' : ''
                        }
                    flex items-center justify-center rounded-full bg-[#f3f2ee] p-2.5 dark:bg-[#1b2e4b]`}
                        onClick={() => setActiveTab2(3)}
                    >
                        <AcademicCapIcon className="h-5 w-5 " />
                    </div>
                    <span
                        className={`text-center block mt-2 ${
                            activeTab2 === 3 ? 'text-primary ' : ''
                        }`}
                    >
                        Academics
                    </span>
                </li>
                <li>
                    <div
                        className={`${
                            activeTab2 === 4 ? '!bg-primary text-white' : ''
                        }
                    flex items-center justify-center rounded-full bg-[#f3f2ee] p-2.5 dark:bg-[#1b2e4b]`}
                        onClick={() => setActiveTab2(4)}
                    >
                        <IconMenuScrumboard className="h-5 w-5 " />
                    </div>
                    <span
                        className={`text-center block mt-2 ${
                            activeTab2 === 4 ? 'text-primary ' : ''
                        }`}
                    >
                     Professional   Experience
                    </span>
                </li>
                <li>
                    <div
                        className={`${
                            activeTab2 === 5 ? '!bg-primary text-white' : ''
                        }
                    flex items-center justify-center rounded-full bg-[#f3f2ee] p-2.5 dark:bg-[#1b2e4b]`}
                        onClick={() => setActiveTab2(5)}
                    >
                        <SparklesIcon className="h-5 w-5 " />
                    </div>
                    <span
                        className={`text-center block mt-2 ${
                            activeTab2 === 5 ? 'text-primary ' : ''
                        }`}
                    >
                        Languages
                    </span>
                </li>

                <li>
                    <div
                        className={`${
                            activeTab2 === 6 ? '!bg-primary text-white' : ''
                        }
                    flex items-center justify-center rounded-full bg-[#f3f2ee] p-2.5 dark:bg-[#1b2e4b]`}
                        onClick={() => setActiveTab2(6)}
                    >
                        <IconUser className="h-5 w-5 " />
                    </div>
                    <span
                        className={`text-center block mt-2 ${
                            activeTab2 === 6 ? 'text-primary ' : ''
                        }`}
                    >
                        Reference
                    </span>
                </li>
                <li>
                    <div
                        className={`${
                            activeTab2 ===7 ? '!bg-primary text-white' : ''
                        }
                    flex items-center justify-center rounded-full bg-[#f3f2ee] p-2.5 dark:bg-[#1b2e4b]`}
                        onClick={() => setActiveTab2(7)}
                    >
                        <IconCloudDownload className="h-5 w-5 " />
                    </div>
                    <span
                        className={`text-center block mt-2 ${
                            activeTab2 === 7 ? 'text-primary ' : ''
                        }`}
                    >
                    Upload documents
                    </span>
                </li>
            </ul>
            <div>
                <p className="mb-5">
                    {activeTab2 === 1 && (
                        <CreatePersonalInfo
                            activeTab2={activeTab2}
                            setActiveTab2={setActiveTab2}
                        />
                    )}
                </p>

                <p className="mb-5">{activeTab2 === 2 && <CreateAddress />}</p>

                <p className="mb-5">{activeTab2 === 3 && <AcademicInfo />}</p>
                <p className="mb-5">
                    {activeTab2 === 4 && <ExperiencesInfo />}
                </p>
                <p className="mb-5">{activeTab2 === 5 && <LanguageInfo />}</p>
                <p className="mb-5">{activeTab2 === 6 && <ReferenceInfo />}</p>
                <p className="mb-5">{activeTab2 === 7 && <FileUpload />}</p>
            </div>

            <div className="flex justify-between">
    <button
        type="button"
        className={`btn btn-primary ${activeTab2 === 1 ? 'hidden' : ''}`}
        onClick={() => setActiveTab2(activeTab2 === 2 ? 1 : activeTab2 - 1)}
    >
        Back
    </button>
    {activeTab2 !== 1 && activeTab2 !== 7 && (
        <button
            type="button"
            className="btn btn-primary ltr:ml-auto rtl:mr-auto"
            onClick={() =>
                setActiveTab2(
                    activeTab2 === 1
                        ? 2
                        : activeTab2 === 2
                        ? 3
                        : activeTab2 === 3
                        ? 4
                        : activeTab2 === 4
                        ? 5
                        : 6
                )
            }
        >
            Next
        </button>
    )}

    {activeTab2 === 7 ? (
        <button
            type="button"
            className="btn btn-primary ltr:ml-auto rtl:mr-auto"
            onClick={() => navigate('/account/applicant/application')}
        >
            Continue
        </button>
    ) : null}
</div>


        </div>
    );
}
