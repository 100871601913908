import {
    GetActiveAplications,
    GetApplications,
    SubmitApplication,
    useApplyApplication,
} from '@/core/hooks/application';
import { GetTopics } from '@/core/hooks/topics';
import { ApplicationType } from '@/core/types/application';
import React, { useEffect, useState } from 'react';
import { useParams, useLocation } from 'react-router-dom';
import {
    EditAnswerInfo,
    GetAnswers,
    useCreateAnswer,
} from '@/core/hooks/answers';
import { useAppSelector } from '@/store';
import { GetSuggestedTopics } from '@/core/hooks/suggested-topics';
import IconHome from '@/components/Icon/IconHome';
import IconFile from '@/components/Icon/IconFile';
import IconUsers from '@/components/Icon/IconUsers';
import Button from '@/components/button';
import { useCreateSupervisor } from '@/core/hooks/supervisor';
import MyProfile from '../../view_profile';
import IconSend from '@/components/Icon/IconSend';
import { getMyProfile } from '@/core/hooks/auth';
import SupervisorsInfo from '../../supervisors';
import SuggestedTopics from '../../suggested-topics';
import IconServer from '@/components/Icon/IconServer';
import IconUser from '@/components/Icon/IconUser';
import Modal from '@/components/modal';
import IconCloudDownload from '@/components/Icon/IconCloudDownload';
import toast from 'react-hot-toast';
import { capitalize, linkClasses } from '@mui/material';

function ApplicationInfo() {
    const { application_id }: any = useParams();
    const { handleGetApplications, applications } = GetApplications();
    const { handleGetAnswers, answers } = GetAnswers();
    const { handleGetTopics, topics } = GetTopics();
    const { applyApplication, loadingApply } = useApplyApplication();
    const [selectedApplication, setSelectedApplication] =
        useState<ApplicationType | null>(null);

    const location = useLocation();
    const query = location.search;

    useEffect(() => {
        handleGetApplications(query);
        handleGetTopics(query);
        handleGetAnswers(query);
    }, [query]);

    useEffect(() => {
        const selected = applications?.list.find(
            (app: { id: any }) => app.id === application_id
        );

        setSelectedApplication(selected);
    }, [applications, application_id]);

    useEffect(() => {
        const selected = applications?.list.find(
            (app: { id: any }) => app.id === application_id
        );
        setSelectedApplication(selected);
    }, [applications, application_id]);

    const handleApply = () => {
        applyApplication(application_id);
    };

    return (
        <div className=" mx-auto mt-8 p-6  rounded-lg shadow-md">
            <h1 className="text-xl font-bold mb-4 text-primary dark:text-gray-400">
                {selectedApplication?.title}
            </h1>

            <div
                className=" dark:text-gray-400  "
                dangerouslySetInnerHTML={{
                    __html: selectedApplication?.description || '',
                }}
            />
            <div>
                <h2 className="text-2xl font-bold mb-2 text-primary">
                    Instructions
                </h2>
                <div
                    className=" dark:text-gray-400  "
                    dangerouslySetInnerHTML={{
                        __html: selectedApplication?.instruction_note || '',
                    }}
                />
            </div>
        </div>
    );
}

function Topics({ setuserChoice2 }: any) {
    const { application_id }: any = useParams();
    const { handleGetApplications, applications } = GetActiveAplications();
    const { handleGetAnswers, answers } = GetAnswers();
    const { handleGetTopics, loadingTopics, topics } = GetTopics();
    const { applyApplication, loadingApply } = useApplyApplication();
    const { handleCreateAnswer, loadingCreateAnswer } = useCreateAnswer();
    const { loadingEditAnswer, handleEditAnswer } = EditAnswerInfo();

    const [selectedApplication, setSelectedApplication] = useState<any>(null);
    const [selectedTopic, setSelectedTopic] = useState<any>(null);

    const [answersMap, setAnswersMap] = useState<{ [key: string]: string }>({});
    const location = useLocation();
    const query = location.search;
    const { userAccount: userInfo }: any = useAppSelector(store => store.user);
    const [userChoice, setUserChoice] = useState('existing');
    const [suggestOwnTopic, setSuggestOwnTopic] = useState(false);

    useEffect(() => {
        setuserChoice2(userChoice);
    }, [userChoice]);

    const updatePageSizeInQuery = (query: string, pageSize: number): string => {
        const urlParams = new URLSearchParams(query);
        urlParams.set('pageSize', pageSize.toString());
        return '?' + urlParams.toString();
    };

    useEffect(() => {
        const updatedQuery = updatePageSizeInQuery(query, 10000000);
        handleGetApplications(query);
        handleGetTopics(updatedQuery);
        handleGetAnswers(query);
    }, [query]);

    useEffect(() => {
        const selected = applications?.list.find(
            (app: { id: any }) => app.id === application_id
        );
        setSelectedApplication(selected);
    }, [applications, application_id]);

    // Set the first topic as the default topic
    useEffect(() => {
        if (selectedApplication?.topics?.length > 0) {
            setSelectedTopic(selectedApplication?.topics[0]); // Default to the first topic
        }
    }, [selectedApplication]);

    const handleSubmitAnswers = (
        topicId: string,
        questionId: string,
        answer: string
    ) => {
        if (!answer) {
            toast.error('Answer is required, type the answer');
            return;
        }

        const submissionData: any = {
            applicationId: application_id,
            topicId,
            questionId,
            answer,
        };

        const existingAnswer = answers?.list.find(
            (ans: {
                application: { id: any };
                question: { id: any };
                user: { id: any };
            }) =>
                ans.application.id === application_id &&
                ans.question.id === questionId &&
                ans.user.id === userInfo.id
        );
        const updatedAnswer: any = { answer };

        if (existingAnswer) {
            handleEditAnswer(updatedAnswer, existingAnswer?.id);
        } else {
            handleCreateAnswer(submissionData);
        }
    };
    useEffect(() => {
        if (selectedTopic?.questions) {
            const updatedAnswersMap: { [key: string]: string } = {};

            selectedTopic.questions.forEach((question: any) => {
                const questionId = question.id;
                const existingAnswer = answers?.list.find(
                    (answer: { application: { id: any }; question: { id: any }; user: { id: any } }) =>
                        answer.application.id === application_id &&
                        answer.question.id === questionId &&
                        answer.user.id === userInfo?.id
                );

                if (existingAnswer) {
                    updatedAnswersMap[questionId] = existingAnswer.answer;
                } else {
                    updatedAnswersMap[questionId] = '';
                }
            });

            setAnswersMap(updatedAnswersMap);
        }
    }, [selectedTopic, answers, application_id, userInfo?.id]);

    const titleCategories = {
        'PERSONAL STATEMENT': ['PERSONAL STATEMENT'],
        'SKILLS AND INTERESTS': [
            'SKILLS AND INTERESTS',
            'SKILLS & INTERESTS',
            'INTERESTS',
        ],
        'LEADERSHIP AND PERSONAL ENGAGEMENT': [
            'LEADERSHIP',
            'ENGAGEMENT',
            'LEADERSHIP AND PERSONAL ENGAGEMENT',
        ],
        'FINANCIAL ASSISTANCE': ['FINANCIAL ASSISTANCE', 'OTHER INFORMATION'],
        'OTHER INFORMATION': ['OTHER INFORMATION'],
    };
    return (
        <div className="container mx-auto p-6">
            {selectedApplication?.allow_suggested_topic && (
                <div className="my-10 mt-3">
                    <div className="my-2">
                        <p className="text-[17px] font-semibold">
                            Select the best option (for this application you
                            will choose either to work on existing topics or
                            suggest your own topic)
                        </p>
                    </div>
                    <label className="inline-flex mx-2 items-center">
                        <input
                            type="radio"
                            className="form-radio"
                            checked={userChoice === 'existing'}
                            onChange={() => {
                                setSelectedTopic(null);
                                setUserChoice('existing');
                            }}
                        />
                        <span className="ml-2 text-[16px]">
                            Choose among existing Topics
                        </span>
                    </label>

                    <label className="inline-flex items-center">
                        <input
                            type="radio"
                            className="form-radio text-primary"
                            checked={userChoice === 'suggested'}
                            onChange={() => {
                                setSelectedTopic(null);
                                setUserChoice('suggested');
                            }}
                        />
                        <span className="ml-2 text-[16px]">
                            Suggest own topic
                        </span>
                    </label>
                </div>
            )}

            {userChoice === 'existing' && (
                <div>
                    <div className="grid grid-cols-1 gap-4 w-full">
                        <div className="mt-8 dark:text-gray-400">
                            <div className="grid grid-cols-1 w-[70%]">
                                {userChoice === 'existing' &&
                                selectedApplication ? (
                                    <select
                                        name="topicSelect"
                                        id="topicSelect"
                                        className="form-select mt-2 p-2 border rounded-md"
                                        onChange={e =>
                                            setSelectedTopic(
                                                selectedApplication.topics.find(
                                                    (topic: any) =>
                                                        topic.id ===
                                                        e.target.value
                                                )
                                            )
                                        }
                                        value={selectedTopic?.id || ''}
                                    >
                                        {selectedApplication?.topics?.map(
                                            (topic: any) => (
                                                <option
                                                    key={topic?.id}
                                                    value={topic?.id}
                                                >
                                                    {topic.name}
                                                </option>
                                            )
                                        )}
                                    </select>
                                ) : (
                                    loadingTopics && (
                                        <p className="text-lg font-bold">
                                            Loading topics...
                                        </p>
                                    )
                                )}
                                {selectedApplication?.topics?.length === 0 && (
                                    <p className="text-lg font-bold">
                                        No topics found for this Application
                                    </p>
                                )}
                            </div>
                        </div>
                    </div>

                    {selectedTopic && (
                        <div>
                            <div key={selectedTopic?.id} className="">
                                <div>
                                    <p className="text-xl my-4 font-bold">
                                        {selectedTopic?.name}
                                    </p>
                                    <div
                                        className="leading-relaxed mt-2"
                                        dangerouslySetInnerHTML={{
                                            __html:
                                                selectedTopic?.instruction_note ||
                                                '',
                                        }}
                                    />
                                    <div className="text-lg my-4">
                                        <strong>
                                            Please answer and click save answer
                                            to each question
                                        </strong>
                                    </div>

                                    <div>
                                        {Object.entries(titleCategories).map(
                                            (
                                                [categoryTitle, keywords],
                                                categoryIndex
                                            ) => (
                                                <div key={categoryTitle}>
                                                    <p className="text-xl my-4 font-bold">
                                                        {categoryIndex + 1}.{' '}
                                                        {categoryTitle}
                                                    </p>
                                                    {selectedTopic?.questions
                                                    ?.filter((question: { title: string }) =>
                                                        keywords.some(keyword => question.title.includes(keyword))
                                                    )
                                                    .map((question: any, questionIndex: number) => (
                                                        <div key={question.id} className="mb-4 mx-4">
                                                            <p className="font-semibold text-md text-gray-900 leading-tight">
                                                                {capitalize(String.fromCharCode(97 + questionIndex))}. {question.description}
                                                            </p>
                                                            <label htmlFor={`answer-${question.id}`} className="mt-3 font-bold">
                                                                Your answer
                                                            </label>
                                                            <textarea
                                                                id={`answer-${question.id}`}
                                                                className="form-textarea p-2 border rounded-md w-full h-32"
                                                                placeholder="Type your answer..."
                                                                required
                                                                value={answersMap[question.id] || ''}
                                                                onChange={e => {
                                                                    const newAnswer = e.target.value;
                                                                    setAnswersMap(prevAnswers => ({
                                                                        ...prevAnswers,
                                                                        [question.id]: newAnswer,
                                                                    }));
                                                                }}
                                                            />
                                                            <div className="mt-4 flex flex-row justify-end">
                                                            <button
                                className="btn btn-primary btn-sm"
                                onClick={() => handleSubmitAnswers(selectedTopic?.id, question.id, answersMap[question.id])}
                            >
                                {answersMap[question.id] ? 'Update Answer' : 'Save Answer'}
                            </button>
                                                            </div>
                                                        </div>
                                                    ))}
                                                </div>
                                            )
                                        )}
                                        <div className="mx-4">
                                            <h4 className="font-semibold text-[15px] text-gray-900 leading-tight">
                                                B. By submitting this
                                                application, note that you agree
                                                with the terms of use of the
                                                information here provided.
                                            </h4>
                                            <p className="text-gray-800 mt-2">
                                                <strong>Terms of use:</strong>{' '}
                                                AIMS-NEI is obligated to protect
                                                the personal data of the AIMS
                                                applicants. Personal information
                                                stored in this database will
                                                therefore be treated
                                                confidentially and used solely
                                                for AIMS' admission and internal
                                                evaluation purposes. I
                                                understand that information
                                                included here and the documents
                                                submitted in support of this
                                                application may be shared with
                                                donors providing scholarship
                                                funding and used for evaluation
                                                and other program purposes. All
                                                information will be treated as
                                                strictly confidential and will
                                                not be released in any way.
                                            </p>
                                        </div>
                                    </div>
                                </div>
                            </div>
                        </div>
                    )}
                </div>
            )}

            {userChoice === 'suggested' && <SuggestedTopics />}
        </div>
    );
}

function CreateSupervisor({ refresh }: any) {
    const [modal, setModal] = React.useState(false);
    const { loadingCreateSupervisor, handleCreateSupervisor } =
        useCreateSupervisor();
    const {
        loadingSuggestedTopics,
        handleGetSuggestedTopics,
        suggestedTopics,
    } = GetSuggestedTopics();

    const query = location.search;

    useEffect(() => {
        handleGetSuggestedTopics(query);
    }, [query]);

    return (
        <div>
            <SupervisorsInfo />
        </div>
    );
}

export default function Apply() {
    const [userChoice, setUserChoice] = useState('suggested');
    const [activeTab2, setActiveTab2] = useState<any>(1);

    const { applyApplication, loadingApply } = useApplyApplication();
    const { application_id }: any = useParams();
    const { loadingPrifile, handleGetProfile, profile } = getMyProfile();
    useEffect(() => {
        handleGetProfile();
    }, []);

    const application = profile?.applications?.find(
        (application: { id: any }) => application?.id === application_id
    );

    const { loadingSubmit, submitApplication } = SubmitApplication();

    const [confirmationOpen, setConfirmationOpen] = useState(false);
    const handleApply = (application_id: any) => {
        setConfirmationOpen(true);
    };

    const tabs = [
        {
            icon: IconHome,
            title: 'Application Information',
            selected: true,
        },
        {
            icon: IconFile,
            title: 'Application Questions',
            selected: true,
        },

        {
            icon: IconCloudDownload,
            title: 'Upload Files',
            selected: true,
        },
    ];
    if (userChoice === 'suggested') {
        tabs.push({
            icon: IconUsers,
            title: 'Supervisors',
            selected: true,
        });
    }

    return (
        <div className="text-gray-700 dark:text-gray-400">
            <div className="panel mt-5" id="icon">
                <div className="inline-block w-full">
                    <div className="relative z-[1]">
                        <div></div>
                        <ul className="mb-5 grid grid-cols-4">
                            <li>
                                <div
                                    className={`${
                                        activeTab2 === 1
                                            ? '!bg-primary text-white'
                                            : ''
                                    }
                flex items-center justify-center rounded-full bg-[#f3f2ee] p-2.5 dark:bg-[#1b2e4b]`}
                                    onClick={() => setActiveTab2(1)}
                                >
                                    <IconHome />
                                </div>
                                <span className="text-center block mt-2">
                                    Application Details
                                </span>
                            </li>
                            <li>
                                <div
                                    className={`${
                                        activeTab2 === 2
                                            ? '!bg-primary text-white'
                                            : ''
                                    }
                flex items-center justify-center rounded-full bg-[#f3f2ee] p-2.5 dark:bg-[#1b2e4b]`}
                                    onClick={() => setActiveTab2(2)}
                                >
                                    <IconServer />
                                </div>
                                <span className="text-center block mt-2">
                                    Application Questions
                                </span>
                            </li>
                            {application?.allow_suggested_topic && (
                                <li>
                                    <div
                                        className={`${
                                            activeTab2 === 3
                                                ? '!bg-primary text-white'
                                                : ''
                                        }
                 flex items-center justify-center rounded-full bg-[#f3f2ee] p-2.5 dark:bg-[#1b2e4b]`}
                                        onClick={() => setActiveTab2(3)}
                                    >
                                        <IconUser />
                                    </div>
                                    <span
                                        className={`text-center block mt-2 ${
                                            activeTab2 === 3
                                                ? 'text-primary '
                                                : ''
                                        }`}
                                    >
                                        Supervisor
                                    </span>
                                </li>
                            )}
                            <li>
                                <div
                                    className={`${
                                        activeTab2 === 4
                                            ? '!bg-primary text-white'
                                            : ''
                                    }
                flex items-center justify-center rounded-full bg-[#f3f2ee] p-2.5 dark:bg-[#1b2e4b]`}
                                    onClick={() => setActiveTab2(4)}
                                >
                                    <IconSend />
                                </div>
                                <span
                                    className={`text-center block mt-2 ${
                                        activeTab2 === 4 ? 'text-primary ' : ''
                                    }`}
                                >
                                    Application Submission
                                </span>
                            </li>
                        </ul>
                    </div>
                    <div>
                        <p className="mb-5">
                            {activeTab2 === 1 && <ApplicationInfo />}
                        </p>

                        <p className="mb-5">
                            {activeTab2 === 2 && (
                                <Topics setuserChoice2={setUserChoice} />
                            )}
                        </p>

                        {application?.allow_suggested_topic && (
                            <>
                                <p className="mb-5">
                                    {activeTab2 === 3 && <CreateSupervisor />}
                                </p>
                            </>
                        )}
                        <p className="mb-5">
                            {activeTab2 === 4 && <ApplicationSubmission />}
                        </p>
                    </div>

                    <div className="flex justify-between">
                        {activeTab2 === 4 ? (
                            <>
                                <button
                                    type="button"
                                    className={`btn btn-primary ${
                                        activeTab2 === 1 ? 'hidden' : ''
                                    }`}
                                    onClick={() =>
                                        setActiveTab2(activeTab2 === 3 ? 2 : 1)
                                    }
                                >
                                    Back
                                </button>
                                <button
                                    className="btn btn-primary "
                                    onClick={handleApply}
                                >
                                    Submit
                                </button>
                            </>
                        ) : (
                            <>
                                <button
                                    type="button"
                                    className={`btn btn-primary ${
                                        activeTab2 === 1 ? 'hidden' : ''
                                    }`}
                                    onClick={() =>
                                        setActiveTab2(activeTab2 === 3 ? 2 : 1)
                                    }
                                >
                                    Back
                                </button>

                                <button
                                    className="btn btn-primary ltr:ml-auto rtl:mr-auto"
                                    onClick={() =>
                                        setActiveTab2(
                                            activeTab2 === 1
                                                ? 2
                                                : activeTab2 === 2 &&
                                                  !application?.allow_suggested_topic
                                                ? 4
                                                : activeTab2 === 2 &&
                                                  application?.allow_suggested_topic
                                                ? 3
                                                : activeTab2 === 3
                                                ? 4
                                                : activeTab2
                                        )
                                    }
                                >
                                    Next
                                </button>
                            </>
                        )}
                    </div>
                </div>
            </div>
            <ConfirmationModal
                open={confirmationOpen}
                setOpen={setConfirmationOpen}
                onConfirm={() => {
                    submitApplication(application_id);
                    setConfirmationOpen(false);
                }}
            />
        </div>
    );
}

function ApplicationSubmission() {
    const { applyApplication, loadingApply } = useApplyApplication();
    const { application_id }: any = useParams();
    const { loadingPrifile, handleGetProfile, profile } = getMyProfile();

    useEffect(() => {
        handleGetProfile();
    }, []);

    const application = profile?.applications?.find(
        (application: { id: any }) => application.id === application_id
    );

    const { loadingSubmit, submitApplication } = SubmitApplication();

    const [confirmationOpen, setConfirmationOpen] = useState(false); // Add this state
    const handleApply = (application_id: any) => {
        setConfirmationOpen(true);
    };

    const titleCategories = {
        'PERSONAL STATEMENT': ['PERSONAL STATEMENT'],
        'SKILLS AND INTERESTS': [
            'SKILLS AND INTERESTS',
            'SKILLS & INTERESTS',
            'INTERESTS',
        ],
        'LEADERSHIP AND PERSONAL ENGAGEMENT': [
            'LEADERSHIP',
            'ENGAGEMENT',
            'LEADERSHIP AND PERSONAL ENGAGEMENT',
        ],
        'FINANCIAL ASSISTANCE': ['FINANCIAL ASSISTANCE', 'OTHER INFORMATION'],
        'OTHER INFORMATION': ['OTHER INFORMATION'],
    };
    return (
        <>
            <MyProfile />
            <div className=" border border-primary mt-3 ">
                <div className=" uppercase text-lg font-bold p-2  bg-primary flex flex-row justify-center  text-white ">
                    Application Details
                </div>
                <div className="p-3 ">
                    <h1 className="text-lg font-bold mb-4 text-primary">
                        {application?.title}
                    </h1>

                    <div
                        className=" dark:text-gray-400  "
                        dangerouslySetInnerHTML={{
                            __html: application?.description || '',
                        }}
                    />
                    {application?.topics &&
                        application?.topics.map((topic: any) => (
                            <div>
                                <p className=" text-xl font-bold  ">Topic</p>
                                <div>
                                    <h3 className="text-lg font-bold mb-2   file: mx-2  text-gray-800 dark:text-gray-400">
                                        Name:{' '}
                                        <span className="font-normal ">
                                            {topic?.name}
                                        </span>
                                    </h3>
                                    <p className="mx-2 font-bold   text-lg text-gray-800 dark:text-gray-400">
                                        Description:{' '}
                                        <span className=" font-normal">
                                            {topic?.description}
                                        </span>
                                    </p>
                                </div>
                                <div>
                                    <p className="my-2  text-lg font-bold  ">
                                        Questions
                                    </p>
                                    <div className="flex flex-col space-y-4">
                                    {Object.entries(titleCategories).map(
    ([categoryTitle, keywords], categoryIndex) => (
        <div key={categoryTitle} className="mb-6">
            <p className="text-xl my-4 font-bold">
                {categoryIndex + 1}. {categoryTitle}
            </p>
            {topic?.questions
                ?.filter((question: { title: string | string[]; }) =>
                    keywords.some(keyword => question.title.includes(keyword))
                )
                .map((question:any, questionIndex:any) => (
                    <div key={question.id} className="mb-4 mx-4 p-4  rounded-lg shadow-md bg-white dark:bg-dark">
                        <p className="font-semibold text-md text-gray-900 leading-tight">
                            {capitalize(String.fromCharCode(97 + questionIndex))}. {question.description}
                        </p>
                        <label htmlFor={`answer-${question.id}`} className="mt-3 font-bold">
                            Answer
                        </label>
                        {question?.answers?.length ? (
                            question.answers.map((answer:any) => (
                                <div key={answer.id} className="flex mt-1 bg-gray-100 dark:bg-gray-900 flex-col items-start p-2 rounded-lg">
                                    <div className="bg-slate-200 dark:bg-gray-700 p-3   rounded-lg w-full">
                                        <p className=" dark:text-gray-200">
                                            {answer.answer ?? 'No answer found'}
                                        </p>
                                    </div>
                                </div>
                            ))
                        ) : (
                            <p className="text-gray-500">No answers available.</p>
                        )}
                    </div>
                ))}
        </div>
    )
)}

                                    </div>
                                </div>
                            </div>
                        ))}

                    <div className="mt-4 ">
                        {application?.suggestedTopics.length > 0 && (
                            <p className=" text-lg font-bold  ">
                                Suggested Topics
                            </p>
                        )}
                        {application?.suggestedTopics &&
                            application?.suggestedTopics.map(
                                (topic: any, index: any) => (
                                    <div>
                                        <div className="mt-2 ">
                                            <h3 className="text-lg  font-bold  mx-2  text-gray-800 dark:text-gray-400">
                                                {index + 1}. name:{' '}
                                                <span className=" ">
                                                    {topic?.title}
                                                </span>
                                            </h3>
                                            <p className="mx-2 font-bold  text-md    text-gray-600 dark:text-gray-400">
                                                Description{' '}
                                                <span className="font-normal">
                                                    {topic?.description}
                                                </span>
                                            </p>
                                        </div>
                                        <div>
                                            <div className="flex flex-col px-4">
                                                <div className="  text-lg font-bold ">
                                                    <p className="">
                                                        Skills Needed
                                                    </p>
                                                </div>

                                                <div className="flex flex-wrap mt-2">
                                                    {topic?.skills_needed?.map(
                                                        (skill: any) => (
                                                            <div
                                                                key={
                                                                    skill?.name
                                                                }
                                                                className="bg-primary text-white py-1 px-2 rounded-full mr-2 mb-2"
                                                            >
                                                                # {skill?.name}
                                                            </div>
                                                        )
                                                    )}
                                                </div>
                                            </div>
                                        </div>
                                    </div>
                                )
                            )}
                    </div>
                </div>
            </div>
            <ConfirmationModal
                open={confirmationOpen}
                setOpen={setConfirmationOpen}
                onConfirm={() => {
                    submitApplication(application_id);
                    setConfirmationOpen(false);
                }}
            />
        </>
    );
}
const ConfirmationModal = ({ open, setOpen, onConfirm }: any) => {
    return (
        <Modal
            show={open}
            setShow={setOpen}
            title="Confirm Submission"
            size="xl"
        >
            <p className="text-sm ">
                By submitting your application, you certify that to the best of
                your knowledge, the information submitted on this application is
                true and complete. You understand that if found to be otherwise,
                it is sufficient cause for refusal or dismissal. If information
                provided in this application changes after submission, you must
                notify the{' '}
                <a
                    href="admissions@aimsammi.org"
                    target="_blank"
                    className="text-blue-500"
                >
                    AMMI admission office
                </a>
            </p>
            <p className="mt-3 text-sm">
                If admitted, your admission is contingent upon reception of your
                original transcripts, a medical certificate and a criminal
                record certificate as well as academic documents reflecting
                academic achievement similar to the assessment at the time of
                your admission.
            </p>

            <p className="mt-3 text-sm">
                You acknowledge that you are responsible for adhering to all
                AIMS regulations.
            </p>
            <div className="flex gap-3 mt-4  justify-end">
               
                <Button onClick={onConfirm}>Submit</Button>
            </div>
        </Modal>
    );
};
