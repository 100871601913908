import {
    get_applications,
    one_application,
    edit_application,
    add_application,
    delete_application,
    apply_application,
    submit_application,
    get_submitted_application,
    my_applications,
    
    get_statistics,
    get_details_user,
    activate_application,
    get_active_application,
    get_details_profile,
    get_admin_statistics,
    assign_application,
    get_incomplete_applications,
    get_application_stats,
    get_nationality_stats,
    assign_application_modulator,
    assign_application_academic_manager,
    modulator_applications,
    get_application_by_id,
} from '@/core/api/application';
import { ApplicationPayload, ApplicationType } from '@/core/types/application';
import { PaginationType } from '@/core/types/shared';
import { useState } from 'react';
import toast from 'react-hot-toast';


export const useGetApplicationById = () => {
    const [loadingApplication, setLoadingApplication] = useState(false);
    const [application, setApplication] = useState<ApplicationType | null>(null);
  
    const handleGetApplicationById = async (id: string) => {
      try {
        setLoadingApplication(true);
        const data = await get_application_by_id(id);
        setApplication(data);
      } catch (error: any) {
        toast.error(error.response.message);
      } finally {
        setLoadingApplication(false);
      }
    };
  
    return {
      loadingApplication,
      application,
      handleGetApplicationById,
    };
  };
export const GetApplications = () => {
    const [loadingApplications, setLoadingApplications] = useState(false);
    const [applications, setApplications]: any = useState<
        ApplicationType[] | null
    >();

    const handleGetApplications = async (query?: string) => {
        try {
            setLoadingApplications(true);
            const data: PaginationType<ApplicationType> =
                await get_applications(query);
            setApplications(data);
        } catch (error: any) {
            toast.error(error.response.message);
        } finally {
            setLoadingApplications(false);
        }
    };

    return {
        loadingApplications,
        applications,
        handleGetApplications,
    };
};

export const GetMyApplication = () => {
    const [loadingApplication, setLoadingApplication] = useState(false);
    const [application, setApplication]: any =
        useState<ApplicationType | null>();

    const handleGetApplication = async (id?: any) => {
        try {
            setLoadingApplication(true);
            const data: ApplicationType = await one_application(id);
            setApplication(data);
        } catch (error: any) {
            toast.error(error.response.message);
        } finally {
            setLoadingApplication(false);
        }
    };

    return {
        loadingApplication,
        application,
        handleGetApplication,
    };
};
export const GetModulatorApplications = () => {
    const [loadingApplication, setLoadingApplication] = useState(false);
    const [application, setApplication]: any =
        useState<ApplicationType | null>();

    const handleGetApplication = async (id?: any) => {
        try {
            setLoadingApplication(true);
            const data: ApplicationType = await modulator_applications(id);
            setApplication(data);
        } catch (error: any) {
            toast.error(error.response.message);
        } finally {
            setLoadingApplication(false);
        }
    };

    return {
        loadingApplication,
        application,
        handleGetApplication,
    };
};

export const EditApplicationInfo = () => {
    const [loadingEditApplication, setLoadingEditApplication] = useState(false);

    const handleEditApplication = async (
        payload: ApplicationPayload,
        id: string
    ) => {
        try {
            setLoadingEditApplication(true);
            await edit_application(payload, id);
            toast.success('Application Information updated');
        } catch (error: any) {
            toast.error(error.response.message || 'Something went wrong');
        } finally {
            setLoadingEditApplication(false);
        }
    };

    return {
        handleEditApplication,
        loadingEditApplication,
    };
};

export const useCreateApplications = () => {
    const [loadingCreate, setLoadingCreate] = useState(false);

    const handleCreateApplication = async (payload: ApplicationPayload) => {
        try {
            setLoadingCreate(true);
            await add_application(payload);
            toast.success('Application information added');
        } catch (error: any) {
            toast.error(error.response.message || 'Something went wrong.');
        } finally {
            setLoadingCreate(false);
        }
    };

    return {
        loadingCreate,
        handleCreateApplication,
    };
};


export const useDeleteApplications = () => {
    const [loadingDelete, setLoadingDelete] = useState(false);

    const handleDeleteApplication = async (id: string) => {
        try {
            setLoadingDelete(true);
            await delete_application(id);
            toast.success('Application information deleted');
        } catch (error: any) {
            toast.error(error.response.message || 'Error Occurred');
        } finally {
            setLoadingDelete(false);
        }
    };

    return {
        loadingDelete,
        handleDeleteApplication,
    };
};

export const ActivateApplication = () => {
    const [loadingActivate, setLoadingActivate] = useState(false);

    const handleActivateApplication = async (id: string) => {
        try {
            setLoadingActivate(true);
            await activate_application(id);
            toast.success('Activation status changed');
        } catch (error: any) {
            toast.error(error.response.message || 'Error Occurred');
        } finally {
            setLoadingActivate(false);
        }
    };

    return {
        loadingActivate,
        handleActivateApplication,
    };
};

export const useApplyApplication = () => {
    const [loadingApply, setLoadingApply] = useState(false);

    const applyApplication = async (applicationId: string) => {
        try {
            setLoadingApply(true);
           
            const data = await apply_application(applicationId)
            
            return data;
        } catch (error: any) {
            toast.error(error.response.message || 'Error submitting application');
            toast.error(error.response.message || 'Error Occurred');
        } finally {
            setLoadingApply(false);
        }
    };

    return {
        loadingApply,
        applyApplication,
    };
};

export const SubmitApplication = () => {
    const [loadingSubmit, setLoadingSubmit] = useState(false);

    const submitApplication = async (applicationId: string) => {
        try {
            setLoadingSubmit(true);
           
            const data = await submit_application(applicationId)
            toast.success(data?.message || 'Application Submitted', {
                duration: 30000,
            });
            return data;
        } catch (error: any) {
            toast.error(error.response.message || 'Error submitting application');
            toast.error(error.response.message || 'Error Occurred');
        } finally {
            setLoadingSubmit(false);
        }
    };

    return {
        loadingSubmit,
        submitApplication,
    };
};


export const GetSubmittedApplications = () => {
    const [loadingApplications, setLoadingApplications] = useState(false);
    const [applications, setApplications]: any = useState<
        ApplicationType[] | null
    >();

    const handleGetApplications = async (query?: string) => {
        try {
            setLoadingApplications(true);
            const data: PaginationType<ApplicationType> =
                await get_submitted_application(query);
            setApplications(data);
        } catch (error: any) {
            toast.error(error.response.message);
        } finally {
            setLoadingApplications(false);
        }
    };

    return {
        loadingApplications,
        applications,
        handleGetApplications,
    };
}
export const GetApplicationStats = () => {
    const [loadingStats, setLoadingstats] = useState(false);
    const [stats, setApplications]: any = useState<
        ApplicationType[] | null
    >();

    const handleGetApplicationStats = async (dateRange: string,applicationId?:string) => {
        try {
            setLoadingstats(true);
            const data: PaginationType<ApplicationType> =
                await get_application_stats(dateRange,applicationId);
            setApplications(data);
        } catch (error: any) {
            toast.error(error.response.message);
        } finally {
            setLoadingstats(false);
        }
    };

    return {
        loadingStats,
        stats,
        handleGetApplicationStats,
    };
}
export const GetIncompleteApplications = () => {
    const [loadingApplications, setLoadingApplications] = useState(false);
    const [applications, setApplications]: any = useState<
        ApplicationType[] | null
    >();

    const handleGetApplications = async (applicationId:string,query?: string,) => {
        try {
            setLoadingApplications(true);
            const data: PaginationType<ApplicationType> =
                await get_incomplete_applications(applicationId,query);
            setApplications(data);
        } catch (error: any) {
            toast.error(error.response.message);
        } finally {
            setLoadingApplications(false);
        }
    };

    return {
        loadingApplications,
        applications,
        handleGetApplications,
    };
}

export const GetActiveAplications = () => {
    const [loadingApplications, setLoadingApplications] = useState(false);
    const [applications, setApplications]: any = useState<
        ApplicationType[] | null
    >();

    const handleGetApplications = async (query?: string) => {
        try {
            setLoadingApplications(true);
            const data: PaginationType<ApplicationType> =
                await get_active_application(query);
            setApplications(data);
        } catch (error: any) {
            toast.error(error.response.message);
        } finally {
            setLoadingApplications(false);
        }
    };

    return {
        loadingApplications,
        applications,
        handleGetApplications,
    };
}

export const getUserSubmittedDetails = () => {
    const [loadingApplications, setLoadingApplications] = useState(false);
    const [applications, setApplications]: any = useState<
        ApplicationType[] | null
    >();

    const handleGetApplications = async (query?: string,userId?:string) => {
        try {
            setLoadingApplications(true);
           
            const data: PaginationType<ApplicationType> = await get_details_user(userId || '', query);
            setApplications(data);
        } catch (error: any) {
            toast.error(error.response.message);
        } finally {
            setLoadingApplications(false);
        }
    };

    return {
        loadingApplications,
        applications,
        handleGetApplications,
    };
}

export const GetSubmittedUserDetails = () => {
    const [loadingUserdata, setLoadinUserData] = useState(false);
    const [usersInfo, setUserInfo]: any = useState<
        ApplicationType[] | null
    >();

    const handleGetUserInfo = async (query?: string,userId?:string) => {
        try {
            setLoadinUserData(true);
           
            const data: PaginationType<ApplicationType> = await get_details_profile(userId || '', query);
            setUserInfo(data);
        } catch (error: any) {
            toast.error(error.response.message);
        } finally {
            setLoadinUserData(false);
        }
    };

    return {
        loadingUserdata,
        usersInfo,
        handleGetUserInfo,
    };
}

export const MyApplication = () => {
    const [loadingApplications, setLoadingApplications] = useState(false);
    const [applications, setApplications]: any = useState<
        ApplicationType[] | null
    >();

    const handleGetApplications = async (query?: string) => {
        try {
            setLoadingApplications(true);
            const data =
                await my_applications();
            setApplications(data);
        } catch (error: any) {
            toast.error(error.response.message);
        } finally {
            setLoadingApplications(false);
        }
    };

    return {
        loadingApplications,
        applications,
        handleGetApplications,
    };
}
export const GetNationalityStats = () => {
    const [loadingStatistics, setLoadingStatistics] = useState(false);
    const [statistics, setStatics]: any = useState<
        ApplicationType[] | null
    >();

    const handleNationalityStats = async (nationality: string,applicationId?:string) => {
        try {
            setLoadingStatistics(true);
            const data =
                await get_nationality_stats(nationality,applicationId);
            setStatics(data);
        } catch (error: any) {
            toast.error(error?.response?.message);
        } finally {
            setLoadingStatistics(false);
        }
    };

    return {
        loadingStatistics,
        statistics,
        handleNationalityStats,
    };
}

export const GetStatistics = () => {
    const [loadingStatistics, setLoadingStatistics] = useState(false);
    const [statistics, setStatics]: any = useState<
        ApplicationType[] | null
    >();

    const handleGetApplications = async () => {
        try {
            setLoadingStatistics(true);
            const data =
                await get_statistics();
            setStatics(data);
        } catch (error: any) {
            toast.error(error?.response?.message);
        } finally {
            setLoadingStatistics(false);
        }
    };

    return {
        loadingStatistics,
        statistics,
        handleGetApplications,
    };
}

export const AdminDashboard = () => {
    const [loadingStatistics, setLoadingStatistics] = useState(false);
    const [statistics, setStatics]: any = useState<
        ApplicationType[] | null
    >();

    const handleGetStatistics = async (applicationId?:string, dateRange?:string) => {
        try {
            setLoadingStatistics(true);
            const data =
                await get_admin_statistics(applicationId, dateRange);
            setStatics(data);
        } catch (error: any) {
            toast.error(error?.response?.message);
        } finally {
            setLoadingStatistics(false);
        }
    };

    return {
        loadingStatistics,
        statistics,
        handleGetStatistics,
    };
}


export const useAssignEvaluetor = () => {
    const [loadingAssing, setLoadingAssing] = useState(false);

    const  handleAssignApplicationToEvaluetor = async (payload: ApplicationPayload) => {
        try {
            setLoadingAssing(true);
            await assign_application(payload);
            toast.success('Application Assignned');
        } catch (error: any) {
            toast.error(error.response.message || 'Something went wrong.');
        } finally {
            setLoadingAssing(false);
        }
    };

    return {
        loadingAssing,
        handleAssignApplicationToEvaluetor,
    };
};

export const useAssignModerator = () => {
    const [loadingAssign, setLoadingAssign] = useState(false);

    const handleAssignModeratorToApplication = async (payload: ApplicationPayload) => {
        try {
            setLoadingAssign(true);
            await assign_application_modulator(payload); 
            toast.success('Moderator Assigned to Application');
        } catch (error: any) {
            toast.error(error.response.message || 'Something went wrong.');
        } finally {
            setLoadingAssign(false);
        }
    };

    return {
        loadingAssign,
        handleAssignModeratorToApplication,
    };
};

export const useAssignAcademicManager = () => {
    const [loadingAssign, setLoadingAssign] = useState(false);

    const handleAssignApplicationToAcademicManager = async (payload: ApplicationPayload) => {
        try {
            setLoadingAssign(true);
            await assign_application_academic_manager(payload); 
            toast.success('Academic Manager Assigned to Application');
        } catch (error: any) {
            toast.error(error.response.message || 'Something went wrong.');
        } finally {
            setLoadingAssign(false);
        }
    };

    return {
        loadingAssign,
        handleAssignApplicationToAcademicManager,
    };
};


export const Add_Experience = () => {
    const [loadingActivate, setLoadingActivate] = useState(false);

    const handleActivateApplication = async (id: string) => {
        try {
            setLoadingActivate(true);
            await activate_application(id);
            toast.success('Activation status changed');
        } catch (error: any) {
            toast.error(error.response.message || 'Error Occurred');
        } finally {
            setLoadingActivate(false);
        }
    };

    return {
        loadingActivate,
        handleActivateApplication,
    };
};

