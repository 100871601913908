import { useEffect, useState } from 'react';
import { TableColumnV2 } from '@/components/datatable/DataTable';
import { useLocation } from 'react-router-dom';
import formatDateToLongForm from '@/core/utils/DateFormattter';
import { TrashIcon } from '@heroicons/react/24/outline';
import { AcademicInfoType } from '@/core/types/academic';
import { GetAcademics } from '@/core/hooks/academics';
import { useAppSelector } from '@/store';
import { AddAcademicInfo } from './modal/add_academic';
import { UpdateAcademicInfo } from './modal/edit_academic';
import DeleteUserAcademic from './modal/delete_academics';
import DataTablev3 from '@/components/datatable/Datatable2';
import { capitalize } from '@mui/material';
import { setPageTitle } from '@/store/themeConfigSlice';
import { useDispatch } from 'react-redux';

export default function AcademicInfo() {
    const [showForm, setShowForm] = useState(false);
    const [open, setOpen] = useState(false);
    const [selected, setSelected] = useState<AcademicInfoType | null>(null);
    const [refresh, setRefresh] = useState(false);
    const location = useLocation();
    const query = location.search;
    const { userAccount: user }: any = useAppSelector(store => store.user);
    console.log(user);
    const { LoadingAcademics, handleGetAccademics, academics } = GetAcademics();

    const toggleFormVisibility = () => {
        setShowForm(!showForm);
    };

    const closeForm = () => {
        setShowForm(false);
    };

    useEffect(() => {
        handleGetAccademics(query);
    }, [query, refresh]);

    const columns: TableColumnV2<AcademicInfoType>[] = [
        {
            title: 'Institution',
            accessor: 'institution',
            render: row => <p className="capitalize">{row?.institution}</p>,
        },
        {
            title: 'Country',
            accessor: 'country',
            render: row => <p className="capitalize">{row?.country}</p>,
        },
        {
            title: 'City',
            accessor: 'city',
            render: row => <p className="capitalize">{row?.city}</p>,
        },
        {
            title: 'Start Date',
            accessor: 'startDate',
            render: row => <p>{formatDateToLongForm(row?.startDate)}</p>,
        },
        {
            title: 'End Date',
            accessor: 'endDate',
            render: row => <p>{formatDateToLongForm(row?.endDate)}</p>,
        },
        {
            title: 'Graduation Date',
            accessor: 'graduationDate',
            render: row => <p>{formatDateToLongForm(row?.graduationDate)}</p>,
        },
        {
            title: 'Level of Study',
            accessor: 'levelOfStudy',
            render: row => <p>{capitalize(row?.levelOfStudy)}</p>,
        },
        {
            title: 'Degree',
            accessor: 'degree',
            render: row => <p>{capitalize(row?.degree)}</p>,
        },
        {
            title: 'Major',
            accessor: 'major',
            render: row => <p>{capitalize(row?.major)}</p>,
        },
        {
            title: 'Grade',
            accessor: 'grade',
            render: row => <p>{row?.grade || '-'}</p>,
        },
        {
            title: 'Highest Qualification',
            accessor: 'highest_qualification',
            render: row => <p>{row?.highest_qualification ? 'Yes' : 'No'}</p>,
        },
        {
            title: 'Language of Instructions',
            accessor: 'language_of_instructions',
            render: row => <p>{row?.language_of_instructions || '-'}</p>,
        },
        {
            title: 'Projects/Publications',
            accessor: 'projectsOrPublications',
            render: row => (
                <div>
                    {row?.projectsOrPublications?.map((project:any, idx:any) => (
                        <div key={idx}>
                            <a href={project.url} target="_blank" rel="noopener noreferrer">
                                {project.title}
                            </a>
                        </div>
                    )) || '-'}
                </div>
            ),
        },
    
        {
            title: 'Date Created',
            accessor: 'created_at',
            render: row => <p>{formatDateToLongForm(row?.created_at)}</p>,
        },
        {
            title: 'Actions',
            accessor: 'actions',
            render: row => (
                <div className="flex gap-2 justify-center">
                    <button
                        onClick={() => {
                            setSelected(row);
                            setOpen(true);
                        }}
                        className="bg-red-700 text-gray-200 p-1 rounded"
                    >
                        <TrashIcon className="w-4" />
                    </button>
                    <UpdateAcademicInfo academicInfo={row} refresh={setRefresh} />
                </div>
            ),
        },
    ];

    const dispatch = useDispatch();
    useEffect(() => {
        dispatch(setPageTitle('Academics'));
    }, [dispatch]);

    return (
        <div className="panel py-4">
            <div>
                <div className="mb-4 p-4 bg-yellow-100 text-yellow-800 rounded">
                    <p>
                        <strong>Note:</strong> Please enter your academic records starting from the latest to the oldest.
                    </p>
                </div>

                <div className="flex flex-row justify-end mb-2">
                    <AddAcademicInfo refresh={setRefresh} />
                </div>
            </div>
            <div>
                <DataTablev3
                    columns={columns}
                    previousPage={0}
                    nextPage={0}
                    currentPage={1}
                    data={academics?.list ?? []}
                    total={academics?.total ?? 0}
                    lastPage={academics?.lastPage ?? 0}
                    isLoading={LoadingAcademics}
                />
                <DeleteUserAcademic
                    refresh={setRefresh}
                    selected={selected}
                    open={open}
                    setOpen={setOpen}
                    title={'Delete Academic Info'}
                />
            </div>
        </div>
    );
}
