import React from 'react';
import { Dialog, Transition } from '@headlessui/react';
import { Fragment } from 'react';
import { z } from 'zod';
import Button from '@/components/button';
import { Form } from '@/components/form';
import { InputField, SelectField } from '@/components/input';
import IconPencil from '@/components/Icon/IconPencil';
import { EditAddress } from '@/core/hooks/address';
import { ExperiencePayload } from '@/core/types/experience';
import IconPlus from '@/components/Icon/IconPlus';
import { useCreateExperiences } from '@/core/hooks/experience';
import { LanguagePayload } from '@/core/types/languages';
import { EditLanguageInfo, useCreateLanguages } from '@/core/hooks/languages';

const experienceSchema = z.object({
    name: z.string().min(1, { message: ' Name is required' }),
    written_level: z.string().min(1, { message: 'Written level is required' }),
    verbal_level: z.string().min(1, { message: 'Verbal level is required' }),
 
});

interface UpdateExperienceProps {
    refresh: (prev: any) => void;
    language: LanguagePayload;
}

export const UpdateLanguage: React.FC<UpdateExperienceProps> = ({ refresh ,language}) => {
    const [modal, setModal] = React.useState(false);
    const { loadingEditLanguage, handleEditLanguage } = EditLanguageInfo(); // Updated hook

    return (
        <div className="">
            <div className="flex items-center justify-center">
            <button
                    type="button"
                    onClick={() => setModal(true)}
                    className="bg-warning text-gray-200 p-1 rounded"
                >
                    <IconPencil className="w-4" />
                </button>
            </div>
            <Transition appear show={modal} as={Fragment}>
                <Dialog as="div" open={modal} onClose={() => setModal(false)}>
                    <Transition.Child
                        as={Fragment}
                        enter="ease-out duration-300"
                        enterFrom="opacity-0"
                        enterTo="opacity-100"
                        leave="ease-in duration-200"
                        leaveFrom="opacity-100"
                        leaveTo="opacity-0"
                    >
                        <div className="fixed inset-0" />
                    </Transition.Child>
                    <div className="fixed inset-0 bg-[black]/60 z-[999] overflow-y-auto">
                        <div className="flex items-start justify-center min-h-screen px-4">
                            <Transition.Child
                                as={Fragment}
                                enter="ease-out duration-300"
                                enterFrom="opacity-0 scale-95"
                                enterTo="opacity-100 scale-100"
                                leave="ease-in duration-200"
                                leaveFrom="opacity-100 scale-100"
                                leaveTo="opacity-0 scale-95"
                            >
                                <Dialog.Panel
                                    as="div"
                                    className="panel border-0 p-0 rounded-lg overflow-hidden my-8 w-full max-w-xl text-black dark:text-white-dark"
                                >
                                    <div className="flex bg-[#fbfbfb] dark:bg-[#121c2c] items-center justify-between px-5 py-3">
                                        <div className="text-lg flex flex-row justify-center w-full">
                                            Add Languages Information
                                        </div>
                                    </div>
                                    <div className="p-5">
                                        <Form<
                                            LanguagePayload,
                                            typeof experienceSchema
                                        >
                                            schema={experienceSchema}
                                            onSubmit={async data => {
                                                await handleEditLanguage(
                                                    data,
                                                    language?.id
                                                );
                                                refresh((prev: any) => !prev);
                                                setModal(false);
                                            }}
                                            className="mt-3"
                                        >
                                            {({ register, formState }) => (
                                                <>
                                                    <div className="px-4flex flex-col  gap-4 text-sm mb-3">
                                                        <InputField
                                                            type="text"
                                                            isLoading={
                                                                loadingEditLanguage
                                                            }
                                                            placeholder="ex, English"
                                                            label="Employer "
                                                            defaultValue={language?.name}
                                                            error={
                                                                formState.errors
                                                                    .name
                                                            }
                                                            registration={register(
                                                                'name'
                                                            )}
                                                        />
                                                            <SelectField
                                                            isLoading={
                                                                loadingEditLanguage
                                                            }
                                                            placeholder="Select written level"
                                                            label="Verbal Level"
                                                            defaultValue={language.verbal_level}
                                                            error={
                                                                formState.errors
                                                                    ?.verbal_level
                                                            }
                                                            options={[
                                                                
                                                                'advanced',
                                                                'intermediate',
                                                                'basic',
                                                                'none',
                                                            ].map(value => ({
                                                                value,
                                                                text:
                                                                    value
                                                                        .charAt(
                                                                            0
                                                                        )
                                                                        .toUpperCase() +
                                                                    value.slice(
                                                                        1
                                                                    ),
                                                            }))}
                                                            registration={register(
                                                                'verbal_level'
                                                            )}
                                                        />

                                                        <SelectField
                                                            isLoading={
                                                                loadingEditLanguage
                                                            }
                                                            placeholder="Select written level"
                                                            label="Written Level"
                                                            defaultValue={language?.written_level}
                                                            error={
                                                                formState.errors
                                                                    .verbal_level
                                                            }
                                                            options={[
                                                                'mother tongue',
                                                                'advanced',
                                                                'intermediate',
                                                                'basic',
                                                                'none',
                                                            ].map(value => ({
                                                                value,
                                                                text:
                                                                    value
                                                                        .charAt(
                                                                            0
                                                                        )
                                                                        .toUpperCase() +
                                                                    value.slice(
                                                                        1
                                                                    ), // Capitalize the first letter
                                                            }))}
                                                            registration={register(
                                                                'written_level'
                                                            )}
                                                        />
                                                    </div>
                                                    <div className="my-3 flex justify-end">
                                                        <Button
                                                            disabled={
                                                                loadingEditLanguage
                                                            }
                                                        >
                                                            Submit
                                                        </Button>
                                                    </div>
                                                </>
                                            )}
                                        </Form>
                                    </div>
                                </Dialog.Panel>
                            </Transition.Child>
                        </div>
                    </div>
                </Dialog>
            </Transition>
        </div>
    );
};
