import { useGetMyPersonalInfo } from '@/core/hooks/personal_information';
import { AddressType } from '@/core/types/address';
import formatDateToLongForm from '@/core/utils/DateFormattter';
import { JSXElementConstructor, Key, ReactElement, ReactNode, ReactPortal, useEffect } from 'react';
import 'tippy.js/dist/tippy.css';
import { capitalize } from '@mui/material';
import { GetAcademics } from '@/core/hooks/academics';
import { AcademicInfoType } from '@/core/types/academic';
import { GetExperiences } from '@/core/hooks/experience';
import { ExperienceType } from '@/core/types/experience';
import { GetLanguages } from '@/core/hooks/languages';
import { GetReferences } from '@/core/hooks/Reference';
import { ReferenceType } from '@/core/types/reference';
import { LanguageType } from '@/core/types/languages';
import { Getfiless } from '@/core/hooks/file';
import { ReactI18NextChild } from 'react-i18next';

function MyProfile() {
    const { handleGetMyPersonalInfo, loadingInformation, personalInfo } =
        useGetMyPersonalInfo();
    const { handleGetAccademics, LoadingAcademics, academics } = GetAcademics();
    const { handleGetExperiences, loadingExperiences, experiences } =
        GetExperiences();
    const { handleGetLanguages, loadingLanguages, languages } = GetLanguages();
    const { handleGetReferences, loadingReferences, references } =
        GetReferences();
    useEffect(() => {
        handleGetMyPersonalInfo();
        handleGetExperiences();
        handleGetLanguages();
        handleGetReferences();
        handleGetAccademics();
    }, []);
    console.log(academics);

    const querry = location.search;
    const { loadingfiless, filess, handleGetfiless } = Getfiless();

    useEffect(() => {
        handleGetfiless(querry);
    }, [querry]);
    return (
        <div className="pt-5 font-semibold flex flex-col gap-3">
            <div className=" border border-primary  ">
                <div className="bg-primary flex flex-row justify-center p-2 text-lg  text-white ">
                    PROFILE INFORMATION
                </div>
                <div className="w-full py-1 flex justify-center items-center   bg-gray-200 dark:bg-slate-800 dark:text-gray-300 text-black font-bold">
                    IDENTIFICATION
                </div>
                <div className="py-4 flex justify-start items-start gap-3 flex-wrap">
                    <div className="p-2 flex flex-row justify-between w-80   rounded-md">
                        <p className="font-bold dark:text-gray-300">
                            First Name:
                        </p>
                        <p className="font-semibold text-gray-500">
                            {personalInfo?.firstName}
                        </p>
                    </div>
                    <div className="p-2 flex flex-row justify-between w-80   rounded-md">
                        <p className="font-bold dark:text-gray-300">
                            Middle Name:
                        </p>
                        <p className="font-semibold text-gray-500">
                            {personalInfo?.middleName || 'None'}
                        </p>
                    </div>
                    <div className="p-2 flex flex-row justify-between w-80   rounded-md">
                        <p className="font-bold dark:text-gray-300">
                            Last Name:
                        </p>
                        <p className="font-semibold text-gray-500">
                            {personalInfo?.lastName || '-'}
                        </p>
                    </div>
                    <div className="p-2 flex flex-row justify-between w-80   rounded-md">
                        <p className="font-bold dark:text-gray-300">
                            Phone Number:
                        </p>
                        <p className="font-semibold text-gray-500">
                            {personalInfo?.phoneNumber}
                        </p>
                    </div>
                    <div className="p-2 flex flex-row justify-between w-80   rounded-md">
                        <p className="font-bold dark:text-gray-300">Email:</p>
                        <p className="font-semibold text-gray-500">
                            {personalInfo?.email}
                        </p>
                    </div>{' '}
                    <div className="p-2 flex flex-row justify-between w-80   rounded-md">
                        <p className="font-bold dark:text-gray-300">Gender:</p>
                        <p className="font-semibold text-gray-500">
                            {personalInfo?.gender === 'prefer_not_to_say'
                                ? 'Not Stated'
                                : personalInfo?.gender}
                        </p>
                    </div>
                    <div className="p-2 flex flex-row justify-between w-80   rounded-md">
                        <p className="font-bold dark:text-gray-300">
                            Date of Birth:
                        </p>
                        <p className="font-semibold text-gray-500">
                            {formatDateToLongForm(personalInfo?.date_of_birth)}
                        </p>
                    </div>
                    <div className="p-2 flex flex-row justify-between w-80   rounded-md">
                        <p className="font-bold dark:text-gray-300">
                            Martial Status:
                        </p>
                        <p className="font-semibold text-gray-500">
                            {personalInfo?.maritalStatus}
                        </p>
                    </div>
                    <div className="p-2 flex flex-row justify-between w-80   rounded-md">
                        <p className="font-bold dark:text-gray-300">
                            Nationality:
                        </p>
                        <p className="font-semibold text-gray-500">
                            {personalInfo?.Nationality}
                        </p>
                    </div>
                    <div className="p-2 flex flex-row justify-between w-80   rounded-md">
                        <p className="font-bold dark:text-gray-300">
                            Citizenship:
                        </p>
                        <p className="font-semibold text-gray-500">
                            {personalInfo?.citizenship || '-'}
                        </p>
                    </div>
                    <div className="p-2 flex flex-row justify-between w-80   rounded-md">
                        <p className="font-bold dark:text-gray-300">
                            LinkedIn:
                        </p>
                        <p className="font-semibold text-gray-500">
                            {personalInfo?.linkedIn}
                        </p>
                    </div>
                    <div className="p-2 flex flex-row justify-between w-80   rounded-md">
                        <p className="font-bold dark:text-gray-300">Github:</p>
                        <p className="font-semibold text-gray-500">
                            {personalInfo?.facebook}
                        </p>
                    </div>
                    <div className="p-2 flex flex-row justify-between w-80   rounded-md">
                        <p className="font-bold dark:text-gray-300">
                            Has Passport :
                        </p>
                        <p className="font-semibold text-gray-500">
                            {personalInfo?.hasPassport ? 'Yes' : 'No'}
                        </p>
                    </div>
                    {personalInfo?.hasPassport && (
                        <>
                            <div className="p-2 flex flex-row justify-between w-80   rounded-md">
                                <p className="font-bold dark:text-gray-300">
                                    Passport Number:
                                </p>
                                <p className="font-semibold text-gray-500">
                                    {personalInfo?.passportNumber || '-'}
                                </p>
                            </div>
                            <div className="p-2 flex flex-row justify-between w-80   rounded-md">
                                <p className="font-bold dark:text-gray-300">
                                    Passport Expiry Date:
                                </p>
                                <p className="font-semibold text-gray-500">
                                    {formatDateToLongForm(
                                        personalInfo?.passport_expired_data
                                    ) || '-'}
                                </p>
                            </div>
                        </>
                    )}
                    {/* <div className="p-2 flex flex-row justify-between w-80 bg-gray-10 0 rounded-md">
                        <p className="font-bold dark:text-gray-300">
                            Is displaced person:
                        </p>
                        <p className="font-semibold text-gray-500">
                            {personalInfo?.displaced_person ? 'Yes' : 'No'}
                        </p>
                    </div> */}
                    {/* <div className="p-2 flex flex-row justify-between w-80   rounded-md">
                        <p className="font-bold dark:text-gray-300">
                            Is refugee:
                        </p>
                        <p className="font-semibold text-gray-500">
                            {personalInfo?.refugee ? 'Yes' : 'No'}
                        </p>
                    </div> */}
                    <div className="p-2 flex flex-row justify-between w-80   rounded-md">
                        <p className="font-bold dark:text-gray-300">
                            {' '}
                            Have disablility:
                        </p>
                        <p className="font-semibold text-gray-500">
                            {personalInfo?.disability ? 'Yes' : 'No'}
                        </p>
                    </div>
                    <div className="p-2 flex flex-row justify-between w-80   rounded-md">
                        {personalInfo?.disability && (
                            <>
                                <p className="font-bold dark:text-gray-300">
                                    {' '}
                                    Disability Information
                                </p>
                                <p>{personalInfo.disability_info}</p>
                            </>
                        )}
                    </div>
                </div>
                <div className="w-full py-1 flex justify-center items-center bg-gray-200 dark:bg-slate-800 dark:text-gray-300 text-black font-bold">
                    ADDRESS INFORMATION
                </div>

                <div className="flex justify-start items-start gap-3 flex-wrap">
                    <div className=" paneltable-responsive  w-full m-5">
                        <table className=" border  rounded  ">
                            <thead className=" ">
                                <tr>
                                    <th className="font-bold">Address Type</th>
                                    <th className="font-bold">City</th>
                                    <th className="font-bold">street</th>
                                    <th className="font-bold">Date Added</th>
                                </tr>
                            </thead>
                            <tbody>
                                {personalInfo?.address?.map(
                                    (data: AddressType) => {
                                        return (
                                            <tr key={data.id}>
                                                <td>
                                                    <div className="whitespace-nowrap">
                                                        {capitalize(data.type)}
                                                    </div>
                                                </td>
                                                <td>{capitalize(data.city)}</td>
                                                <td>{data.street || '-'}</td>
                                                <td>
                                                    {formatDateToLongForm(
                                                        data.created_at
                                                    ) || '-'}{' '}
                                                </td>
                                            </tr>
                                        );
                                    }
                                )}
                            </tbody>
                        </table>
                    </div>
                </div>
                <div className="w-full py-1 flex justify-center items-center bg-gray-200 dark:bg-slate-800 dark:text-gray-300 text-black font-bold uppercase ">
                    Academic Information
                </div>

                <div className="flex justify-start items-start gap-3 flex-wrap">
                    <div className="table-responsive  w-full m-5">
                        <table className="border rounded   ">
                            <thead>
                                <tr>
                                    <th className="font-bold">Institution</th>
                                    <th className="font-bold">Country</th>

                                    <th className="font-bold whitespace-nowrap">
                                        Graduation Date
                                    </th>
                                    <th className="font-bold whitespace-nowrap ">
                                        Level of Study
                                    </th>
                                    <th className="font-bold">Degree</th>
                                    <th className="font-bold">Major</th>

                                    <th className="font-bold whitespace-nowrap">
                                        Language of Instructions
                                    </th>
                                    <th className="font-bold whitespace-nowrap">
                                        Highest Qualification
                                    </th>
                                    <th className="font-bold">Grade Type</th>
                                    <th className="font-bold">Grade</th>
                                    <th className="font-bold">
                                        Project or Publication
                                    </th>
                                </tr>
                            </thead>
                            <tbody>
                                {academics?.list?.map(
                                    (data: AcademicInfoType) => {
                                        return (
                                            <tr key={data.id}>
                                                <td>{data.institution}</td>
                                                <td>{data.country}</td>

                                                <td>
                                                    {formatDateToLongForm(
                                                        data.graduationDate
                                                    ) || '-'}
                                                </td>
                                                <td>{data.levelOfStudy}</td>
                                                <td>{data.degree}</td>
                                                <td>{data.major}</td>

                                                <td>
                                                    {data.language_of_instructions ||
                                                        '-'}
                                                </td>
                                                <td>
                                                    {data.highest_qualification
                                                        ? 'Yes'
                                                        : 'No'}
                                                </td>
                                                <td className='uppercase'>{data.gradeType}</td>
                                                <td>{data.grade}</td>
                                                <td>
                                                    { data.projectsOrPublications &&  data.projectsOrPublications.map(
                                                        (project: { url: string | undefined; title: string | number | boolean | ReactElement<any, string | JSXElementConstructor<any>> | Iterable<ReactNode> | ReactPortal | Iterable<ReactI18NextChild> | null | undefined; }, index: Key | null | undefined) => (
                                                            <div key={index}>
                                                                <a
                                                                    href={
                                                                        project.url
                                                                    }
                                                                    target="_blank"
                                                                    rel="noopener noreferrer"
                                                                >
                                                                    {
                                                                        project.title
                                                                    }
                                                                </a>
                                                            </div>
                                                        )
                                                    )}
                                                </td>
                                            </tr>
                                        );
                                    }
                                )}
                            </tbody>
                        </table>
                    </div>
                </div>
                <div className="w-full py-1 flex justify-center items-center bg-gray-200 dark:bg-slate-800 dark:text-gray-300 text-black font-bold uppercase ">
                    Professionl Experience
                </div>

                <div className="flex justify-start items-start gap-3 flex-wrap">
                    <div className="table-responsive  w-full m-5">
                        <table className="border rounded">
                            <thead>
                                <tr>
                                    <th className="font-bold whitespace-nowrap">
                                        Employer Name
                                    </th>
                                    <th className="font-bold">Country</th>

                                    <th className="font-bold whitespace-nowrap">
                                        Start Date
                                    </th>
                                    <th className="font-bold whitespace-nowrap">
                                        End Date
                                    </th>
                                    <th className="font-bold">Position</th>
                                    <th className="font-bold whitespace-nowrap">
                                        Roles and Responsibilities
                                    </th>
                                    <th className="font-bold">Type</th>
                                    <th className="font-bold whitespace-nowrap">
                                        Employer Sector
                                    </th>
                                </tr>
                            </thead>
                            <tbody>
                                {experiences?.list?.map(
                                    (data: ExperienceType) => {
                                        return (
                                            <tr key={data.id}>
                                                <td>{data.employer_name}</td>
                                                <td>{data.country}</td>

                                                <td>
                                                    {formatDateToLongForm(
                                                        data.start_date
                                                    ) || '-'}
                                                </td>
                                                <td>
                                                    {formatDateToLongForm(
                                                        data.end_date
                                                    ) || '-'}
                                                </td>
                                                <td>{data.position}</td>
                                                <td>
                                                    {data.roles_and_responsibilities ||
                                                        '-'}
                                                </td>
                                                <td>{data.type}</td>
                                                <td>
                                                    {data.employer_sector ||
                                                        '-'}
                                                </td>
                                            </tr>
                                        );
                                    }
                                )}
                            </tbody>
                        </table>
                    </div>
                </div>
                <div className="w-full py-1 flex justify-center items-center bg-gray-200 dark:bg-slate-800 dark:text-gray-300 text-black font-bold uppercase ">
                    Languages
                </div>

                <div className="flex justify-start items-start gap-3 flex-wrap">
                    <div className="table-responsive  w-full m-5">
                        <table className="border rounded">
                            <thead>
                                <tr>
                                    <th className="font-bold">Language</th>
                                    <th className="font-bold">Verbal Level</th>
                                    <th className="font-bold">Written Level</th>
                                </tr>
                            </thead>
                            <tbody>
                                {languages?.list?.map((data: LanguageType) => {
                                    return (
                                        <tr key={data.id}>
                                            <td>{data.name}</td>
                                            <td>{data.verbal_level}</td>
                                            <td>{data.written_level}</td>
                                        </tr>
                                    );
                                })}
                            </tbody>
                        </table>
                    </div>
                </div>
                <div className="w-full py-1 flex justify-center items-center bg-gray-200 dark:bg-slate-800 dark:text-gray-300 text-black font-bold uppercase ">
                    reference information
                </div>

                <div className="flex justify-start items-start gap-3 flex-wrap">
                    <div className="table-responsive  w-full m-5">
                        <table className="border rounded">
                            <thead>
                                <tr>
                                    <th className="font-bold whitespace-nowrap">
                                        First Name
                                    </th>
                                    <th className="font-bold whitespace-nowrap">
                                        Last Name
                                    </th>
                                    <th className="font-bold">Company</th>
                                    <th className="font-bold">Position</th>
                                    <th className="font-bold whitespace-nowrap">
                                        Relationship
                                    </th>
                                    <th className="font-bold">Email</th>
                                    <th className="font-bold whitespace-nowrap ">
                                        Phone Number
                                    </th>
                                    <th className="font-bold">Country</th>
                                </tr>
                            </thead>
                            <tbody>
                                {references?.list?.map(
                                    (data: ReferenceType) => {
                                        return (
                                            <tr key={data.id}>
                                                <td>{data.firstName}</td>
                                                <td>{data.lastName}</td>
                                                <td>{data.company}</td>
                                                <td>{data.position}</td>
                                                <td>{data.relationship}</td>
                                                <td>{data.email}</td>
                                                <td>{data.phoneNumber}</td>
                                                <td>{data.country}</td>
                                            </tr>
                                        );
                                    }
                                )}
                            </tbody>
                        </table>
                    </div>
                </div>
                <div>
                    <div className="panel mx-w-lg">
                        <div className="w-full py-1 flex justify-center items-center bg-gray-200 dark:bg-slate-800 dark:text-gray-300 text-black font-bold uppercase ">
                            Attachment
                        </div>
                        {filess &&
                            filess?.list?.map((file: any, index: number) => (
                                <a
                                    href={file.file_link}
                                    target="_blank"
                                    rel="noopener noreferrer"
                                    className="text-blue-500 font-semibold hover:underline capitalize"
                                >
                                    <p key={file.id} className="py-2 ">
                                        {index + 1}.{' '}
                                        <span className="text-gray-600">
                                            {file.fileType.name}
                                        </span>
                                    </p>
                                </a>
                            ))}
                    </div>
                </div>
            </div>
        </div>
    );
}

export default MyProfile;
