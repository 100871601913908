import React, { useState } from 'react';
import { Dialog, Transition } from '@headlessui/react';
import { Fragment } from 'react';
import { z } from 'zod';
import Button from '@/components/button';
import { Form } from '@/components/form';
import { InputField, SelectField } from '@/components/input';
import IconPlus from '@/components/Icon/IconPlus';
import { useCreateAcademics } from '@/core/hooks/academics';
import { countries } from 'countries-list';
import IconTrash from '@/components/Icon/IconTrash';

const academicInfoSchema = z.object({
    institution: z.string().min(1, { message: 'Institution is required' }),
    country: z.string().min(1, { message: 'Country is required' }),
    city: z.string().min(1, { message: 'City is required' }),
    startDate: z.string().min(1, { message: 'Start Date is required' }),
    endDate: z.string().min(1, { message: 'End Date is required' }),
    graduationDate: z.string().optional(),
    levelOfStudy: z.string().min(1, { message: 'Level of Study is required' }),
    degree: z.string().min(1, { message: 'Degree is required' }),
    major: z.string().min(1, { message: 'Major is required' }),
    language_of_instructions: z
        .string()
        .min(1, { message: 'Language is required' }),
    highest_qualification: z
        .string()
        .min(1, { message: 'Highest Qualification is required' }),
    gradeType: z.string().min(1, { message: 'Grade Type is required' }),
    grade: z.string().min(1, { message: 'Grade is required' }),
    projectsOrPublications: z
        .array(
            z.object({
                title: z.string().min(1, { message: 'Title is required' }),
                url: z.string().url({ message: 'Invalid URL' }).optional(),
            })
        )
        .optional(),
});

interface AddAcademicInfoProps {
    refresh: (prev: any) => void;
}

export const AddAcademicInfo: React.FC<AddAcademicInfoProps> = ({
    refresh,
}) => {
    const [modal, setModal] = useState(false);
    const { loadingCreate, handleCreate } = useCreateAcademics();
    const countryOptions = Object.values(countries).map(country => ({
        value: country.name,
        text: country.name,
    }));

    const [graduationDate, setGraduationDate] = useState('');
    const [projects, setProjects] = useState([{ title: '', url: '' }]);

    const handleProjectChange = (
        index: number,
        field: 'title' | 'url',
        value: string
    ) => {
        const updatedProjects = [...projects];
        updatedProjects[index][field] = value;
        setProjects(updatedProjects);
    };

    const addProjectField = () => {
        setProjects([...projects, { title: '', url: '' }]);
    };

    const removeProjectField = (index: number) => {
        const updatedProjects = [...projects];
        updatedProjects.splice(index, 1);
        setProjects(updatedProjects);
    };

    const validProjects = projects.filter(
        (project) => project.title.trim() && project.url.trim()
      );
      

    return (
        <div className="">
            <div className="flex items-center justify-center">
                <button
                    type="button"
                    onClick={() => setModal(true)}
                    className="btn-primary btn btn-sm"
                >
                    <IconPlus className="w-4" /> Academic info
                </button>
            </div>
            <Transition appear show={modal} as={Fragment}>
                <Dialog as="div" open={modal} onClose={() => setModal(false)}>
                    <Transition.Child
                        as={Fragment}
                        enter="ease-out duration-300"
                        enterFrom="opacity-0"
                        enterTo="opacity-100"
                        leave="ease-in duration-200"
                        leaveFrom="opacity-100"
                        leaveTo="opacity-0"
                    >
                        <div className="fixed inset-0" />
                    </Transition.Child>
                    <div className="fixed inset-0 bg-[black]/60 z-[999] overflow-y-auto">
                        <div className="flex items-start justify-center min-h-screen px-4">
                            <Transition.Child
                                as={Fragment}
                                enter="ease-out duration-300"
                                enterFrom="opacity-0 scale-95"
                                enterTo="opacity-100 scale-100"
                                leave="ease-in duration-200"
                                leaveFrom="opacity-100 scale-100"
                                leaveTo="opacity-0 scale-95"
                            >
                                <Dialog.Panel
                                    as="div"
                                    className="panel border-0 p-0 rounded-lg overflow-hidden my-8 w-full max-w-xl text-black dark:text-white-dark"
                                >
                                    <div className="flex bg-[#fbfbfb] dark:bg-[#121c2c] items-center justify-between px-5 py-3">
                                        <div className="text-lg flex flex-row justify-center w-full">
                                            Add Academic Information
                                        </div>
                                    </div>
                                    <div className="p-5">
                                        <Form<any, typeof academicInfoSchema>
                                            schema={academicInfoSchema}
                                            onSubmit={async data => {
                                                data.highest_qualification =
                                                    data.highest_qualification ===
                                                    'true';
                                                if (graduationDate) {
                                                    data.graduationDate =
                                                        graduationDate;
                                                }
                                                if(validProjects.length >0 ){
                                                    data.projectsOrPublications =
                                                    projects;
                                                }
                                             
                                                await handleCreate(data);
                                                refresh((prev: any) => !prev);
                                                setModal(false);
                                            }}
                                            className="mt-3"
                                        >
                                            {({ register, formState }: any) => (
                                                <>
                                                    <div className="px-4 grid grid-cols-2 gap-4 text-sm mb-3">
                                                        {/* Existing fields */}
                                                        <InputField
                                                            type="text"
                                                            isLoading={
                                                                loadingCreate
                                                            }
                                                            placeholder="e.g., University of Example"
                                                            label="Institution"
                                                            error={
                                                                formState.errors
                                                                    .institution
                                                            }
                                                            registration={register(
                                                                'institution'
                                                            )}
                                                        />
                                                        <SelectField
                                                            placeholder="Select Country or Location"
                                                            isLoading={
                                                                loadingCreate
                                                            }
                                                            label="Country/Location"
                                                            error={
                                                                formState.errors
                                                                    .country
                                                            }
                                                            options={
                                                                countryOptions
                                                            }
                                                            registration={register(
                                                                'country'
                                                            )}
                                                        />
                                                        <InputField
                                                            type="text"
                                                            isLoading={
                                                                loadingCreate
                                                            }
                                                            placeholder="e.g., New York"
                                                            label="City"
                                                            error={
                                                                formState.errors
                                                                    .city
                                                            }
                                                            registration={register(
                                                                'city'
                                                            )}
                                                        />
                                                        <InputField
                                                            type="date"
                                                            isLoading={
                                                                loadingCreate
                                                            }
                                                            placeholder="e.g., 2022-01-01"
                                                            label="Start Date"
                                                            error={
                                                                formState.errors
                                                                    .startDate
                                                            }
                                                            registration={register(
                                                                'startDate'
                                                            )}
                                                        />
                                                        <InputField
                                                            type="date"
                                                            isLoading={
                                                                loadingCreate
                                                            }
                                                            placeholder="e.g., 2026-12-31"
                                                            label="End Date"
                                                            error={
                                                                formState.errors
                                                                    .endDate
                                                            }
                                                            registration={register(
                                                                'endDate'
                                                            )}
                                                        />
                                                        <InputField
                                                            type="date"
                                                            isLoading={
                                                                loadingCreate
                                                            }
                                                            placeholder="e.g., 2026-05-15"
                                                            label="Graduation Date"
                                                            error={
                                                                formState.errors
                                                                    .graduationDate
                                                            }
                                                            registration={register(
                                                                'graduationDate',
                                                                {
                                                                    setValueAs:
                                                                        (
                                                                            value: any
                                                                        ) => {
                                                                            setGraduationDate(
                                                                                value
                                                                            );
                                                                            return value;
                                                                        },
                                                                }
                                                            )}
                                                        />
                                                        <SelectField
                                                            placeholder="Level of Study"
                                                            isLoading={
                                                                loadingCreate
                                                            }
                                                            label="Level of Study"
                                                            error={
                                                                formState.errors
                                                                    .levelOfStudy
                                                            }
                                                            options={[
                                                                {
                                                                    value: 'diploma',
                                                                    text: 'Diploma',
                                                                },
                                                                {
                                                                    value: 'undergraduate',
                                                                    text: 'Undergraduate',
                                                                },
                                                                {
                                                                    value: 'postgraduate_diploma',
                                                                    text: 'PostGraduate Diploma',
                                                                },
                                                                {
                                                                    value: 'masters',
                                                                    text: 'Masters',
                                                                },
                                                                {
                                                                    value: 'phd',
                                                                    text: 'PhD',
                                                                },
                                                            ]}
                                                            registration={register(
                                                                'levelOfStudy'
                                                            )}
                                                        />
                                                        <InputField
                                                            type="text"
                                                            isLoading={
                                                                loadingCreate
                                                            }
                                                            placeholder="e.g., Bachelors"
                                                            label="Degree"
                                                            error={
                                                                formState.errors
                                                                    .degree
                                                            }
                                                            registration={register(
                                                                'degree'
                                                            )}
                                                        />
                                                        <InputField
                                                            type="text"
                                                            isLoading={
                                                                loadingCreate
                                                            }
                                                            placeholder="e.g., Software Engineering"
                                                            label="Major"
                                                            error={
                                                                formState.errors
                                                                    .major
                                                            }
                                                            registration={register(
                                                                'major'
                                                            )}
                                                        />
                                                        <InputField
                                                            type="text"
                                                            isLoading={
                                                                loadingCreate
                                                            }
                                                            placeholder="e.g., English"
                                                            label="Language of Instruction"
                                                            error={
                                                                formState.errors
                                                                    .language_of_instructions
                                                            }
                                                            registration={register(
                                                                'language_of_instructions'
                                                            )}
                                                        />
                                                        <SelectField
                                                            placeholder="Is this the highest qualification?"
                                                            isLoading={
                                                                loadingCreate
                                                            }
                                                            label="Highest Qualification"
                                                            error={
                                                                formState.errors
                                                                    .highest_qualification
                                                            }
                                                            options={[
                                                                {
                                                                    value: 'true',
                                                                    text: 'Yes',
                                                                },
                                                                {
                                                                    value: 'false',
                                                                    text: 'No',
                                                                },
                                                            ]}
                                                            registration={register(
                                                                'highest_qualification'
                                                            )}
                                                        />

                                                        {/* New fields for grade and projects */}
                                                        <SelectField
                                                            placeholder="Grade Type"
                                                            isLoading={
                                                                loadingCreate
                                                            }
                                                            label="Grade Type"
                                                            error={
                                                                formState.errors
                                                                    .gradeType
                                                            }
                                                            options={[
                                                                {
                                                                    value: 'distinction',
                                                                    text: 'Distinction',
                                                                },
                                                                {
                                                                    value: 'percentage',
                                                                    text: 'Percentage (%)',
                                                                },
                                                                {
                                                                    value: 'gpa',
                                                                    text: 'GPA',
                                                                },
                                                            ]}
                                                            registration={register(
                                                                'gradeType'
                                                            )}
                                                        />
                                                        <InputField
                                                            type="text"
                                                            isLoading={
                                                                loadingCreate
                                                            }
                                                            placeholder="e.g., 3.8, 80%"
                                                            label="Grade"
                                                            error={
                                                                formState.errors
                                                                    .grade
                                                            }
                                                            registration={register(
                                                                'grade'
                                                            )}
                                                        />

                                                        <div className="col-span-2">
                                                            <div className="flex flex-col gap-2">
                                                                <label className="text-sm font-bold">
                                                                    Projects or
                                                                    Publications
                                                                </label>

                                                                {/* Iterate over each project */}
                                                                {projects.map(
                                                                    (
                                                                        project,
                                                                        index
                                                                    ) => (
                                                                        <div
                                                                            key={
                                                                                index
                                                                            }
                                                                            className="flex items-center gap-3 mx-4"
                                                                        >
                                                                            {/* Project Title */}
                                                                            <div className="flex-1">
                                                                                <InputField
                                                                                    type="text"
                                                                                    placeholder="Project Title"
                                                                                    isLoading={
                                                                                        loadingCreate
                                                                                    }
                                                                                    label="Project Title"
                                                                                    value={
                                                                                        project.title
                                                                                    }
                                                                                    onChange={e =>
                                                                                        handleProjectChange(
                                                                                            index,
                                                                                            'title',
                                                                                            e
                                                                                                .target
                                                                                                .value
                                                                                        )
                                                                                    }
                                                                                />
                                                                            </div>

                                                                            {/* Project URL */}
                                                                            <div className="flex-1">
                                                                                <InputField
                                                                                    type="text"
                                                                                    placeholder="Project URL (Optional)"
                                                                                    isLoading={
                                                                                        loadingCreate
                                                                                    }
                                                                                    label="Project URL"
                                                                                    value={
                                                                                        project.url
                                                                                    }
                                                                                    onChange={e =>
                                                                                        handleProjectChange(
                                                                                            index,
                                                                                            'url',
                                                                                            e
                                                                                                .target
                                                                                                .value
                                                                                        )
                                                                                    }
                                                                                />
                                                                            </div>

                                                                            
                                                                            <button
                                                                                type="button"
                                                                                onClick={() =>
                                                                                    removeProjectField(
                                                                                        index
                                                                                    )
                                                                                }
                                                                                className="w-6 h-6 mt-2 flex items-center justify-center rounded-lg bg-red-600 text-white hover:bg-red-700 focus:outline-none focus:ring-2 focus:ring-red-500"
                                                                            >
                                                                                <IconTrash />
                                                                            </button>
                                                                        </div>
                                                                    )
                                                                )}

                                                                {/* Add Project Button */}
                                                                <div className="flex justify-end mx-4">
                                                                    <button
                                                                        type="button"
                                                                        onClick={
                                                                            addProjectField
                                                                        }
                                                                        className="btn-primary btn btn-sm w-1/4"
                                                                    >
                                                                        Add
                                                                        Project
                                                                    </button>
                                                                </div>
                                                            </div>
                                                        </div>
                                                    </div>
                                                    <div className="my-3 flex justify-end">
                                                        <Button
                                                            disabled={
                                                                loadingCreate
                                                            }
                                                        >
                                                            Submit
                                                        </Button>
                                                    </div>
                                                </>
                                            )}
                                        </Form>
                                    </div>
                                </Dialog.Panel>
                            </Transition.Child>
                        </div>
                    </div>
                </Dialog>
            </Transition>
        </div>
    );
};
